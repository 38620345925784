import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9a8da13e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "max-w-[1440px]" }
const _hoisted_2 = ["href", "onMouseover", "onMouseleave"]
const _hoisted_3 = { class: "flex h-full w-full flex-col items-center justify-center" }
const _hoisted_4 = { class: "max-w-[200px] max-h-[180px] p-[20px]" }
const _hoisted_5 = ["title", "alt", "src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LineAnimation = _resolveComponent("LineAnimation")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex flex-col items-center w-full", _ctx.basic ? ' bg-[#25AEF2]' : ' bg-transparent'])
  }, [
    (_ctx.basic)
      ? (_openBlock(), _createBlock(_component_LineAnimation, {
          key: 0,
          class: "w-full lg:pl-[130px] lg:my-[80px] xs:my-[40px] xs:pl-[16px] md:max-w-[1440px]",
          text: 'Our partners',
          line_color: 'white',
          text_color: 'white'
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass(["grid xs:grid-cols-2 lg:grid-cols-4 md:gap-[20px] md:gap-[80px] lg:px-[130px] xs:px-[30px]", _ctx.basic ? 'mb-[30px]lg:pb-[80px]' : 'mb-[10px] lg:pb-[0px]'])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.basic ? _ctx.partners_color.slice(0, 8) : _ctx.partners_color, (icon) => {
          return (_openBlock(), _createElementBlock("div", {
            key: icon.title,
            href: icon.link,
            onMouseover: ($event: any) => (icon.hover = true),
            onMouseleave: ($event: any) => (icon.hover = false)
          }, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("img", {
                  class: "h-auto resize",
                  title: icon.title,
                  alt: icon.alt,
                  src: 
                  _ctx.basic
                    ? icon.path_white
                    : icon.hover
                    ? icon.path_color
                    : icon.path_grey
                
                }, null, 8, _hoisted_5)
              ])
            ])
          ], 40, _hoisted_2))
        }), 128))
      ], 2)
    ])
  ], 2))
}