<template>
  <div
    class="h-[calc(100vh-76px)] md:h-[calc(100vh-100px)] pt-[30px] md:pt-[95px] mx-auto flex justify-start top-container"
  >
    <div class="max-width-limit w-full relative">
      <div class="text montserrat">
        {{ first_line }}
      </div>
      <div class="text montserrat">
        {{ second_line }}
      </div>
      <LineAnimation
        class="xs:pt-[50px] md:pt-[64px] pr-[50px]"
        :main_title="true"
        :text_color="'#000000'"
        :line_color="'#29adf2'"
        big
      >
        <slot></slot
      ></LineAnimation>

      <div class="absolute right-0 bottom-0">
        <span
          class="block rotate-90 h-[143px] mr-[16px] md:mr-[64px] xl:mr-[130px] mb-[70px]"
        >
          <span
            class="inline-block bounce h-[1px] w-[76px] bg-[#29ADF2] mb-[0.3em] mr-[16px]"
          />
          <span
            class="animate-pulse text-[18px] text-[#29ADF2] font-light montserrat"
            >Scroll</span
          >
        </span>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";
import LineAnimation from "@/components/shared/LineAnimation.vue";
@Options({
  props: {
    first_line: String,
    second_line: String,
  },
  components: {
    LineAnimation,
  },
})
export default class PageTitle extends Vue {}
</script>
<style scoped>
.text {
  @apply xl:text-[190px] xl:leading-[200px] lg:text-[140px] lg:leading-[150px] md:text-[100px] md:leading-[150px] sm:text-[70px] sm:leading-[75px]  xs:text-[55px] xs:leading-[57px]  text-[#29adf2] font-black text-left;
}

@media screen and (max-height: 950px) and (min-width: 768px) {
  .top-container {
    @apply pt-[0px] mt-[-10px] mb-[10px];
  }
}
</style>
