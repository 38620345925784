<template>
  <PageLayout>
    <PageTitle :first_line="'FRESH'" :second_line="'& TASTY'"
      ><span class="font-bold text-lemonblue">services</span> for those, who do
      not<br class="hidden lg:block" />
      want to be
      <span class="font-bold text-lemonblue"
        >just <span class="whitespace-nowrap">another one.</span></span
      >
    </PageTitle>
    <div style="overflow: hidden; position: relative">
      <SliderVertical class="xs:mb-[40px] sm:mb-[80px] md:mb-[100px]" />
    </div>
    <AreasAndRoles />
    <div class="text-lemonblue text-left">
      <h2 class="px-[16px] pt-[56px] pb-[32px] max-width-limit">
        Together we will achieve improvement in product
      </h2>
      <div class="max-w-[1440px] xl:px-[130px] md:px-[64px] mx-auto">
        <div
          class="flex justify-between gap-[24px] pt-[32px] px-[16px] md:px-0 pb-[15px] lg:py-0 overflow-y-hidden overflow-x-scroll no-scrollbar"
          @scroll="handleScroll"
        >
          <button
            class="special-text-button text-[40px] pr-[2px] pb-[10px]"
            :key="button.text"
            v-for="button in buttons"
            :class="button.selected ? 'active' : ''"
            @click="setSelected(button.text)"
          >
            {{ button.text }}
          </button>
        </div>
        <ScrollbarComponent
          :value="buttonsScroll"
          class="mt-[8px] right-[-20px] md:right-0 w-[80%] float-right scrollbar"
        />
      </div>

      <div
        class="lg:flex items-center px-[16px] pt-[50px] max-width-limit w-full"
      >
        <div
          :key="button.text"
          v-for="button in getSelectedButton"
          class="hidden lg:block z-10 min-h-[287px] rounded-[16px] w-[min(35vw,495px)]"
          :style="`background-image: url(${getImgUrl(
            button.img
          )}); background-size: contain; background-position: center;`"
        />
        <div
          class="bg-background z-10 h-[256px] w-[40px] border hidden lg:block"
        />
        <div
          class="lg:h-[230px] md:h-[150px] h-[230px] lg:w-[30vw] w-full lg:ml-[24px] flex items-start relative overflow-hidden"
        >
          <p
            class="paragraph"
            :class="buttons[0].selected ? 'moveBack' : 'moveLeft'"
          >
            We will establish the process of bringing new innovations to con-
            sumers from concept to testing through distribution. New product
            development strategies look at improving existing products to
            invigorate an existing market or create new products that the market
            seeks.
          </p>
          <p
            class="paragraph"
            :class="buttons[1].selected ? 'moveBack' : 'moveLeft'"
          >
            Our Product design is about creating products that meet the needs of
            its users through business strategy, data analysis, user experience
            design, user research, user interface design, motion design,
            mockups, and prototypes. It’s all about problem solving and design
            thinking, which is part of that design process.
          </p>
          <p
            class="paragraph"
            :class="buttons[2].selected ? 'moveBack' : 'moveLeft'"
          >
            We strive to create innovative products with the latest
            technologies, all while using the agile software development
            approach. Our team utilizes development requirements and solutions
            through the collaborative effort of self-organizing and
            cross-functional teams and their customer/end user.
          </p>
          <p
            class="paragraph"
            :class="buttons[3].selected ? 'moveBack' : 'moveLeft'"
          >
            Our product software implementation method is a systematically
            structured approach to effectively integrate innovative products
            into a company’s organizational structure. An approach which we use
            to cover the complete process of bringing a new innovative product
            to the market.
          </p>
        </div>
        <div
          class="bg-lemonblue z-10 h-[1px] w-full border lg:hidden my-[20px]"
        />
        <img
          :key="button.text"
          v-for="button in getSelectedButton"
          :src="getImgUrl(button.img)"
          class="mx-auto lg:hidden z-10 object-contain"
          alt="company people"
        />
      </div>
    </div>
    <div class="xs:mx-4 sm:mx-6 xs:px-4 sm:px-24 mt-40 mb-10">
      <SliderTech />
    </div>
  </PageLayout>
</template>

<script>
import { useHead } from "@vueuse/head";
import { defineComponent } from "vue";
import getSiteMeta from "../utils/getSiteMeta";
import PageLayout from "@/components/PageLayout.vue";
import PageTitle from "@/components/shared/PageTitle.vue";
import AreasAndRoles from "@/components/shared/AreasAndRoles.vue";
import SliderTech from "@/components/shared/SliderTech.vue";
import SliderVertical from "@/components/shared/SliderVertical.vue";
import ScrollbarComponent from "@/components/Scrollbar.vue";

export default defineComponent({
  name: "ServicesView",
  setup() {
    const meta = {
      title: "Services | Blue Lemons",
      description:
        "Fresh and tasty services for those, who do not want to be just another one.",
      url: "http://bluelemons.sk/services",
    };

    useHead({
      title: meta.title,
      meta: getSiteMeta(meta),
    });
  },
  data: () => {
    return {
      buttons: [
        { text: "strategy", selected: true, img: "services/1.png" },
        { text: "design", selected: false, img: "services/2.png" },
        {
          text: "development",
          selected: false,
          img: "services/3.png",
        },
        {
          text: "implementation",
          selected: false,
          img: "services/4.png",
        },
      ],
      buttonsScroll: 0,
    };
  },
  methods: {
    setSelected(id) {
      this.buttons.forEach((button) => {
        button.selected = button.text === id;
      });
    },
    getImgUrl(img) {
      return require("@/assets/" + img);
    },
    handleScroll(event) {
      this.buttonsScroll =
        event.target.scrollLeft /
        (event.target.scrollWidth - event.target.clientWidth);
    },
  },
  computed: {
    getSelectedButton() {
      return this.buttons.filter((b) => b.selected);
    },
  },
  components: {
    ScrollbarComponent,
    PageLayout,
    PageTitle,
    AreasAndRoles,
    SliderTech,
    SliderVertical,
  },
});
</script>

<style scoped>
.moveLeft {
  @apply lg:translate-x-[-30vw] translate-x-[-100%] opacity-0;
}
.moveBack {
  transition-delay: 400ms;
  transform: translateX(0px);
  opacity: 1;
}

.paragraph {
  @apply text-black transition ease-in duration-[500ms] absolute left-0 lg:w-[30vw];
}

.border {
  border: none;
  border-right: 1px solid #29adf2;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@media screen and (min-width: 1170px) {
  .scrollbar {
    display: none;
  }
}
</style>
