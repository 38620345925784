<template>
  <div
    class="max-w-[1440px] md:px-[64px] xl:px-[130px] px-[16px] mx-auto lg:min-w-[1000px] min-h-[400px]"
  >
    <div>
      <div class="flex flex-row justify-evenly w-full md:px-[50px]">
        <ButtonWithCircle
          class="text-[40px]"
          v-for="button in buttons"
          :key="button.text"
          :text="button.text"
          :value="10"
          @click="
            !button.selected ? (click = !click) : (click = click);
            setSelected(button.text);
          "
          :selected="button.selected"
        />
      </div>
      <div class="flex flex-row items-center w-full relative py-[30px]">
        <div
          ref="line"
          :class="
            showAnimation
              ? 'delay-[200ms] translate-x-[-100%]  duration-[500ms]'
              : 'translate-x-[-105%]'
          "
          class="absolute w-full h-full bg-background z-10 border-r-solid border-r-[1px] border-r-[#29adf2]"
        ></div>
        <div
          class="absolute w-full h-[90%] grid lg:grid-rows-3 lg:grid-cols-4 grid-cols-2 grid-rows-4 md:gap-y-[32px] gap-y-[16px] gap-x-[16px] px-[16px] md:pl-[80px] pl-[64px]"
        >
          <div
            v-for="(item, index) in areas"
            :key="index"
            class="col-span-1 max-w-[170px] text-left montserrat md:text-[24px] xs:text-[16px] flex flex-col items-center transition ease-in"
            :class="
              !click
                ? index >= 0 && index < 4
                  ? 'delay-[500ms] translate-x-0 opacity-100 duration-[1200ms]'
                  : index >= 4 && index < 8
                  ? 'delay-[1500ms] translate-x-0 opacity-100 duration-[1200ms]'
                  : 'delay-[2500ms] translate-x-0 opacity-100 duration-[1200ms]'
                : index >= 0 && index < 4
                ? 'delay-[300ms] translate-x-[-100vw] opacity-0 duration-[100ms]'
                : index >= 4 && index < 8
                ? 'delay-[200ms] translate-x-[-100vw] opacity-0 duration-[100ms]'
                : 'delay-[100ms] translate-x-[-100vw] opacity-0 duration-[100ms]'
            "
          >
            <div
              class="text-[#002132] w-full font-light text-left relative pr-[33px]"
            >
              <div
                class="absolute montserrat text-[12px] font-light top-[-5px] left-[-26px] w-[10px] z-10 border-l-solid border-l-[1px] border-l-black pl-[3px]"
              >
                {{ index < 9 ? "0" + (index + 1) : index + 1 }}
              </div>
              {{ item.title }}
            </div>
          </div>
        </div>
        <div
          class="w-full h-full grid lg:grid-rows-3 lg:grid-cols-4 grid-cols-2 grid-rows-4 md:gap-y-[32px] gap-y-[16px] gap-x-[16px] px-[16px] md:pl-[80px] pl-[64px]"
        >
          <div
            v-for="(item, index) in roles"
            :key="index"
            class="transition opacity-0 ease-in col-span-1 max-w-[170px] text-left montserrat md:text-[24px] xs:text-[16px] flex flex-col items-center"
            :class="
              click
                ? index >= 0 && index < 4
                  ? 'delay-[500ms] translate-x-0 opacity-100  duration-[1200ms]'
                  : index >= 4 && index < 8
                  ? 'delay-[1500ms] translate-x-0 opacity-100 duration-[1200ms]'
                  : 'delay-[2500ms] translate-x-0 opacity-100 duration-[1200ms]'
                : index >= 0 && index < 4
                ? 'delay-[300ms] translate-x-[-100vw] opacity-0 duration-[100ms]'
                : index >= 4 && index < 8
                ? 'delay-[200ms] translate-x-[-100vw] opacity-0 duration-[100ms]'
                : 'delay-[100ms] translate-x-[-100vw] opacity-0 duration-[100ms]'
            "
          >
            <div
              class="text-[#002132] w-full font-light text-left relative pr-[33px]"
            >
              <div
                class="absolute montserrat text-[12px] font-light top-[-5px] left-[-26px] w-[10px] z-10 border-l-solid border-l-[1px] border-l-black pl-[3px]"
              >
                {{ index < 9 ? "0" + (index + 1) : index + 1 }}
              </div>
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";
import ButtonWithCircle from "@/components/buttons/ButtonWithCircle.vue";

@Options({
  components: { ButtonWithCircle },
  data: () => {
    return {
      showAnimation: false,
    };
  },
  methods: {
    isInViewport() {
      const el = this.$refs.line;
      if (el) {
        const rect = el.getBoundingClientRect();
        if (
          rect.top >= 0 &&
          rect.bottom <=
            (window.innerHeight + 200 ||
              document.documentElement.clientHeight + 200)
        ) {
          this.showAnimation = true;
        }
      } else {
        this.showAnimation = false;
      }
    },
  },
  mounted() {
    document.addEventListener("scroll", this.isInViewport);
  },
  unmounted() {
    document.removeEventListener("scroll", this.isInViewport);
  },
})
export default class AreasAndRoles extends Vue {
  buttons = [
    { text: "Areas", selected: true },
    { text: "Roles", selected: false },
  ];
  indexes = [4, 3];
  click = false;
  setSelected(id) {
    this.buttons.forEach((button) => {
      button.selected = button.text === id;
    });
  }

  roles = [
    {
      title: "Front-End Developers",
      colspan: 1,
      start: 1,
    },
    {
      title: "Back-End Developers",
      colspan: 1,
      start: 3,
    },
    {
      title: "Scrum Masters",
      colspan: 1,
      start: 5,
    },
    {
      title: "UX designers",
      colspan: 1,
      start: 2,
    },
    {
      title: "UI designers",
      colspan: 1,
      start: 4,
    },
    {
      title: "Project Managers",
      colspan: 1,
      start: 1,
    },
    {
      title: "Solution Architects",
      colspan: 1,
      start: 3,
    },
    {
      title: "Quality Assurance",
      colspan: 1,
      start: 5,
    },
    {
      title: "Testers",
      colspan: 1,
      start: 2,
    },
    {
      title: "DevOps",
      colspan: 1,
      start: 4,
    },
  ];
  areas = [
    {
      title: "Logistic",
      colspan: 1,
      start: 1,
    },
    {
      title: "Fintech",
      colspan: 1,
      start: 3,
    },
    {
      title: "NGO",
      colspan: 1,
      start: 5,
    },
    {
      title: "Government",
      colspan: 1,
      start: 2,
    },
    {
      title: "Industry",
      colspan: 1,
      start: 4,
    },
    {
      title: "Healthcare",
      colspan: 1,
      start: 1,
    },
    {
      title: "CMS",
      colspan: 1,
      start: 3,
    },
    {
      title: "IoT & ML",
      colspan: 1,
      start: 5,
    },
    {
      title: "Smart Solutions",
      colspan: 1,
      start: 2,
    },
    {
      title: "Culture",
      colspan: 1,
      start: 4,
    },
  ];
}
</script>
<style scoped>
.custom-image {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
</style>
