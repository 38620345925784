<template>
  <div class="relative w-full flex flex-col items-center overflow-hidden">
    <div
      v-if="$route.path !== '/contact'"
      id="contact"
      class="flex flex-col w-full h-[155px] md:min-h-[385px] z-30 justify-center mb-[64px] md:mb-[80px] mt-[80px] max-w-[1440px] lg:px-[130px] md:mt-[120px]"
    >
      <LineAnimation
        class="mb-[32px] md:mb-[64px] pl-[16px] lg:pl-[0px]"
        text="Let's talk about ideas"
        :text_color="'#000000'"
        :line_color="'#29adf2'"
      />
      <div
        class="flex w-full justify-center divide-x divide-[#29adf2] flex-col md:flex-row gap-4"
      >
        <div
          v-for="contact in contacts"
          :key="contact.title"
          class="text-center border-r-solid xl:px-[28px] xs:px-[10px] md:px-[18px]"
        >
          <div
            class="montserrat text-[#29adf2] pb-[5px] md:pb-[25px] title-contact"
          >
            {{ contact.title }}
          </div>
          <div
            class="montserrat text-[#29adf2] info-contact pb-[5px] md:pb-[25px]"
          >
            <a :href="`mailto: ${contact.mail}`">{{ contact.mail }}</a>
          </div>
          <div class="montserrat text-[#29adf2] info-contact">
            <a :href="`tel: ${contact.tel}`">{{ contact.tel }}</a>
          </div>
        </div>
      </div>
    </div>
    <div
      class="absolute min-w-[889px] min-h-[585px] md:min-w-[1700px] md:min-h-[810px] bottom-[97px] md:bottom-[13.5px] ml-[-16px]"
      ref="animContainer"
    >
      <lottie-animation
        ref="anim"
        :animationData="require('@/assets/animations/drop_full.json')"
        :loop="true"
        :autoPlay="false"
        class="pointer-events-none overflow-hidden"
      />
    </div>
    <div
      class="flex flex-col justify-center items-center w-full h-[383px] relative"
    >
      <img src="@/assets/footer/logo.svg" alt="blue lemons logo" />
      <div class="flex flex-row justify-between mt-[55px] w-[136px] z-10">
        <a
          v-for="icon in icons"
          :key="icon.title"
          :href="icon.link"
          target="_blank"
        >
          <img
            class="w-[16px] h-[16px] cursor-pointer hover:scale-150 transition ease-in duration-[100ms]"
            :title="icon.title"
            :alt="icon.alt"
            :src="icon.path"
          />
        </a>
      </div>
      <div class="text-white text-[14px] mt-[20px]">
        &copy;Blue Lemons {{ new Date().getFullYear() }}
      </div>
      <div
        class="w-full h-[383px] bg-lemonblue absolute bottom-0 right-0 z-[-1] shadow-[0_0_1px_0.5px_#29ADF2]"
      ></div>
    </div>
  </div>
</template>
<script>
import LineAnimation from "@/components/shared/LineAnimation.vue";
import { LottieAnimation } from "lottie-web-vue";

export default {
  name: "FooterComponent",
  props: {
    show_drop: Boolean,
  },
  data() {
    return {
      show_animation: false,
      contacts: [
        {
          tel: "+421 901 903 234",
          mail: "info@bluelemons.sk",
          title: "Košice",
        },
      ],
      icons: [
        {
          path: require("@/assets/footer/Instagram_icon.png"),
          alt: "Instagram Icon",
          title: "Instagram Icon",
          link: "https://www.instagram.com/bluelemons_sk/",
        },
        {
          path: require("@/assets/footer/Facebook_icon.png"),
          alt: "Facebook Icon",
          title: "Facebook Icon",
          link: "https://www.facebook.com/BlueLemonsSK/",
        },
        {
          path: require("@/assets/footer/Twitter_icon.png"),
          alt: "Twitter Icon",
          title: "Twitter Icon",
          link: "https://twitter.com/BlueLemons_sk",
        },
        {
          path: require("@/assets/footer/LinkedIn_icon.png"),
          alt: "LinkedIn Icon",
          title: "LinkedIn Icon",
          link: "https://www.linkedin.com/company/bluelemonssk/",
        },
      ],
    };
  },
  components: {
    LottieAnimation,
    LineAnimation,
  },
  methods: {
    cumulativeOffset(element) {
      let top = 0,
        left = 0;
      do {
        top += element.offsetTop || 0;
        left += element.offsetLeft || 0;
        element = element.offsetParent;
      } while (element);

      return {
        top: top,
        left: left,
      };
    },
    checkScroll() {
      if (
        this.$refs.animContainer &&
        this.show_drop &&
        window.scrollY + window.innerHeight >
          this.cumulativeOffset(this.$refs.animContainer).top
      ) {
        this.$refs.anim?.play();
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.checkScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.checkScroll);
  },
};
</script>
<style scoped>
.title-contact {
  @apply xl:text-[64px] xl:leading-[79px] mlg:text-[50px] mlg:leading-[55px] lg:text-[45px] lg:leading-[50px] md:text-[40px] md:leading-[45px] sm:text-[25px] sm:leading-[30px] xs:text-[19px] xs:leading-[25px] font-extrabold;
}

.info-contact {
  @apply xl:text-[30px] xl:leading-[37px] mlg:text-[25px] mlg:leading-[30px] lg:text-[20px] lg:leading-[25px] md:text-[18px] md:leading-[22pxs] sm:text-[15px] sm:leading-[20px] xs:text-[10px] xs:leading-[15px];
}
</style>
