<template>
  <PageLayout>
    <PageTitle :first_line="'JUST'" :second_line="'TASTE'"
      >some of our completed <br class="hidden md:block" />
      <span class="font-bold text-[#29adf2]"
        >goals and projects.</span
      ></PageTitle
    >
    <div class="">
      <div
        class="flex flex-col md:flex-wrap md:flex-row justify-left gap-x-[5%] mb-[50px] max-w-[1440px] lg:px-[130px] md:px-[50px] px-[16px] mx-auto pt-[50px] md:pb-[20px] buttons"
      >
        <ButtonWithCircle
          v-for="button in buttons"
          :key="button"
          :text="button.text"
          :value="button.value"
          :selected="button.selected"
          @click="setSelected(button.id)"
        />
      </div>
    </div>

    <GridLayout :custom_cards="cards" :items="items" />
  </PageLayout>
</template>

<script lang="ts">
import { useHead } from "@vueuse/head";
import { defineComponent } from "vue";
import getSiteMeta from "../utils/getSiteMeta";
import PageLayout from "@/components/PageLayout.vue";
import PageTitle from "@/components/shared/PageTitle.vue";
import ButtonWithCircle from "@/components/buttons/ButtonWithCircle.vue";
import GridLayout from "@/components/GridLayout.vue";
interface Img {
  img: string;
}
interface Project {
  type: string;
  name1: string;
  name2: string | null;
  work_images: [Img];
  path: string;
}

export default defineComponent({
  name: "WorkView",
  mounted() {
    this.setSelected("All");
  },
  setup() {
    const meta = {
      title: "Work | Blue Lemons",
      description:
        "Just taste some of our completed goals on mobile app, web app, iot and machine learning.",
      url: "http://bluelemons.sk/work",
    };

    useHead({
      title: meta.title,
      meta: getSiteMeta(meta),
    });
  },
  data(): {
    items: any;
    projects: any;
    filtered_projects: any;
    selected: any;
    cards: any;
    buttons: any;
  } {
    return {
      projects: require("@/assets/detail projektu/projects.json"),
      filtered_projects: [],
      selected: "",
      cards: [
        { top: 0, left: -57, width: 861, height: 488 },
        { top: 244, left: 828, width: 456, height: 634 },
        { top: 648, left: 61, width: 648, height: 428 },
        { top: 1038, left: 732, width: 861, height: 488 },
        { top: 1156, left: 157, width: 456, height: 634 },
        { top: 1606, left: 828, width: 648, height: 428 },
      ],
      items: [],
      buttons: [
        { text: "All", value: 16, selected: true, id: "All" },
        { text: "Mobile app", value: 7, selected: false, id: "Mobile app" },
        { text: "Web app", value: 8, selected: false, id: "Web app" },
        { text: "IoT & ML", value: 1, selected: false, id: "IoT & ML" },
      ],
    };
  },
  methods: {
    setSelected(id) {
      this.buttons.forEach((button) => {
        button.selected = button.id === id;
      });
      if (id == "All") {
        this.filtered_projects = this.projects;
      } else {
        this.filtered_projects = this.projects.filter(
          (item) => item.type == id
        );
      }
      this.items = [];
      let items = this.filtered_projects.map((project: Project, index) => {
        if (project.work_images.at(index % 3) != undefined) {
          return {
            img: project.work_images.at(index % 3)?.img,
            redirect: project.path,
            type: project.type,
            name: project.name2
              ? project.name1 + " " + project.name2
              : project.name1,
          };
        }
      });
      this.items = items;
    },
  },
  components: {
    PageLayout,
    PageTitle,
    ButtonWithCircle,
    GridLayout,
  },
});
</script>

<style scoped>
/* width */
.buttons::-webkit-scrollbar {
  margin-left: 20vw;
  border-radius: 20px;
  height: 4px;
  background-color: #29adf2;
  width: 80px !important;
}

/* Track */
.buttons::-webkit-scrollbar-track {
  background: #e5e9ea;
  width: 80px !important;
}

/* Handle */
.buttons::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #29adf2;
}
</style>
