<template>
  <div
    class="hidden md:block relative max-w-[1440px] mx-auto"
    :style="`height: ${`min(${((this.height / 1440) * 100).toFixed(2)}vw,${
      this.height
    }px)`};`"
  >
    <GridItem
      v-for="item in myItems"
      :key="item.top"
      :item="item"
      :show_more="show_eye"
    />
    <div class="absolute bottom-0 left-[min(16.4vw,236px)]">
      <EyeButton
        v-if="show_eye && items.length > 6"
        eye-left
        :less="showMore"
        @click="showMore = !showMore"
      />
    </div>
  </div>

  <div class="md:hidden flex flex-col items-center">
    <div v-for="item in myItems" :key="item.top" class="py-[32px] px-[16px]">
      <router-link
        :to="item.redirect ? '/work/' + item.redirect : ''"
        :class="item.redirect ? 'cursor-pointer' : 'cursor-default'"
      >
        <div v-if="item.height <= item.width" class="">
          <div class="rounded-[16px]">
            <img v-if="item.img" :src="getImgUrl(item.img)" />
          </div>
          <h6 v-if="show_eye" class="text-left mt-[8px] text-[22px]">
            {{ item.projectName }}
          </h6>
          <div v-if="show_eye" class="flex items-center justify-start">
            <div class="w-[16px] h-[1px] bg-lemonblue mr-[6px]" />
            <p class="button1-light">{{ item.type }}</p>
          </div>
        </div>
        <div v-else class="">
          <div class="rounded-[16px]">
            <img v-if="item.img" :src="getImgUrl(item.img)" />
          </div>
          <h6 v-if="show_eye" class="text-left mt-[8px] text-[22px]">
            {{ item.projectName }}
          </h6>
          <div v-if="show_eye" class="flex items-center justify-start">
            <div class="w-[16px] h-[1px] bg-lemonblue mr-[6px]" />
            <p class="button1-light">{{ item.type }}</p>
          </div>
        </div>
      </router-link>
    </div>

    <div class="flex justify-end text-left">
      <EyeButton
        v-if="show_eye && items.length > 6"
        eye-left
        :less="showMore"
        @click="showMore = !showMore"
      />
    </div>
  </div>
</template>

<script>
import EyeButton from "@/components/buttons/EyeButton";
import GridItem from "@/components/GridItem.vue";

export default {
  name: "GridLayout",
  props: {
    custom_cards: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    show_eye: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data: () => {
    return {
      showMore: false,
      height: 2035,
      itemCount: 0,
      myItems: [],
    };
  },
  watch: {
    items: function () {
      this.setItems();
    },
    showMore: function () {
      this.setItems();
    },
  },
  mounted() {
    this.cards = this.custom_cards;
    this.setItems();
  },
  methods: {
    getImgUrl: function (img) {
      return require("@/assets/" + img);
    },
    setItems() {
      if (this) {
        this.itemCount = 0;
        const newItems = [];
        this.items.forEach((item) => {
          if (!this.showMore && this.itemCount >= this.cards.length) return;
          if (this.itemCount < this.items.length) {
            const actualCard = this.cards[this.itemCount % this.cards.length];
            newItems.push({
              top:
                actualCard.top +
                Math.floor(this.itemCount / this.cards.length) * 1950,
              left: actualCard.left,
              width: actualCard.width,
              height: actualCard.height,
              img: item.img,
              type: item.type,
              redirect: item.redirect,
              projectName: item.name,
            });
            this.height =
              actualCard.top +
              actualCard.height +
              Math.floor(this.itemCount / this.cards.length) * 1950;
            if (this.itemCount % 2 === 0) this.height += 220;
            this.itemCount++;
          }
        });
        this.myItems = newItems;
      }
    },
  },
  components: {
    GridItem,
    EyeButton,
  },
};
</script>

<style scoped>
.overlay .round-button {
  visibility: hidden;
}
.overlay p {
  visibility: hidden;
}
.overlay:hover {
  visibility: visible;
  background: rgba(0, 33, 50, 0.6);
}

.overlay:hover .round-button {
  visibility: visible;
}

.overlay:hover p {
  visibility: visible;
}
</style>
