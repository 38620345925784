const type = "website";
const url = "http://bluelemons.sk/";
const title = "Squeeze out innovations | Blue Lemons";
const description =
  "We create digital products that drive profit, ability and growth. We create products that people love to use.";
const mainImage = "http://bluelemons.sk/img/balkon.3c8d5001.png";
export default function getMeta(meta) {
  return [
    {
      hid: "description",
      name: "description",
      // eslint-disable-next-line no-undef
      content: (meta && meta.description) || description,
    },
    {
      hid: "og:type",
      property: "og:type",
      // eslint-disable-next-line no-undef
      content: (meta && meta.type) || type,
    },
    {
      hid: "og:url",
      property: "og:url",
      // eslint-disable-next-line no-undef
      content: (meta && meta.url) || url,
    },
    {
      hid: "og:title",
      property: "og:title",
      // eslint-disable-next-line no-undef
      content: (meta && meta.title) || title,
    },
    {
      hid: "og:description",
      property: "og:description",
      // eslint-disable-next-line no-undef
      content: (meta && meta.description) || description,
    },
    {
      hid: "og:image",
      property: "og:image",
      // eslint-disable-next-line no-undef
      content: (meta && meta.mainImage) || mainImage,
    },
    {
      hid: "twitter:url",
      name: "twitter:url",
      // eslint-disable-next-line no-undef
      content: (meta && meta.url) || url,
    },
    {
      hid: "twitter:title",
      name: "twitter:title",
      // eslint-disable-next-line no-undef
      content: (meta && meta.title) || title,
    },
    {
      hid: "twitter:description",
      name: "twitter:description",
      // eslint-disable-next-line no-undef
      content: (meta && meta.description) || description,
    },
    {
      hid: "twitter:image",
      name: "twitter:image",
      // eslint-disable-next-line no-undef
      content: (meta && meta.mainImage) || mainImage,
    },
  ];
}
