<template>
  <div class="min-h-[calc(100vh-100px)] pt-[3vh] mx-auto">
    <div class="h-full relative max-width-limit">
      <div class="text montserrat">
        {{ first_line }}
      </div>
      <div class="text montserrat">
        {{ second_line }}
      </div>
      <div
        class="under-text montserrat pt-[24px]"
        :class="second_line ? 'pt-[16px]' : ''"
      >
        {{ under_text }}
      </div>
      <div class="small-text montserrat pt-[16px]">
        {{ small_text }}
      </div>
      <LineAnimation
        class="xs:pt-[50px] md:pt-[48px] md:w-[90%] w-[90%]"
        :main_title="true"
        :text_color="'#000000'"
        :line_color="'#29adf2'"
        big
      >
        <slot></slot
      ></LineAnimation>
    </div>
  </div>
  <div class="absolute right-0 bottom-0">
    <span
      class="block rotate-90 h-[143px] mr-[16px] md:mr-[64px] xl:mr-[130px] mb-[13px]"
    >
      <span
        class="inline-block bounce h-[1px] w-[76px] bg-[#29ADF2] mb-[0.3em] mr-[16px]"
      />
      <span
        class="animate-pulse text-[18px] text-[#29ADF2] font-light montserrat"
        >Scroll</span
      >
    </span>
  </div>
  <!-- <div
    class="pt-[70px] max-w-[1440px] xl:px-[130px] md:px-[64px] px-[16px] mx-auto"
  >
    <LineAnimation
      v-if="second_line"
      class="xs:pt-[50px] md:pt-[64px]"
      :main_title="true"
      :text_color="'#000000'"
      :line_color="'#29adf2'"
      big
    >
      <slot></slot
    ></LineAnimation>
  </div> -->
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";
import LineAnimation from "@/components/shared/LineAnimation.vue";
@Options({
  props: {
    first_line: String,
    second_line: String,
    under_text: String,
    small_text: String,
  },
  components: {
    LineAnimation,
  },
})
export default class PageTitle extends Vue {}
</script>
<style scoped>
.text {
  @apply xl:text-[190px] xl:leading-[200px] lg:text-[140px] lg:leading-[150px] md:text-[100px] md:leading-[150px] sm:text-[70px] sm:leading-[75px]  xs:text-[55px] xs:leading-[57px]  text-[#29adf2] font-black text-left;
}
.under-text {
  color: transparent;
  -webkit-text-stroke: 1px #29adf2;
  @apply xl:text-[80px] xl:leading-[100px] lg:text-[80px] lg:leading-[100px] md:text-[60px] md:leading-[70px] xs:text-[40px] xs:leading-[49px] lg:font-black xs:font-black text-left;
}
.small-text {
  @apply lg:text-[34px] lg:leading-[40px] md:text-[29px] md:leading-[35px] xs:text-[24px] xs:leading-[30px] font-light text-left text-[#29adf2];
}
</style>
