<template>
  <router-link :to="'/work/' + item.redirect">
    <div
      class="absolute rounded-[16px] overflow-hidden"
      :style="`top: ${getTop}; left: ${getLeft}; width: ${getWidth}; height: ${getHeight}`"
    >
      <div
        v-if="show_more"
        class="absolute overlay w-full h-full flex flex-col justify-center items-center z-10"
      >
        <router-link
          :to="'/work/' + item.redirect"
          class="round-button my-[40px] cursor-pointer"
          >Show more</router-link
        >
        <p class="text-white max-w-[300px]">{{ item.projectName }}</p>
      </div>
      <img class="" v-if="item.img" :src="getImgUrl(item.img)" />
    </div>
  </router-link>
</template>

<script>
export default {
  name: "GridItem",
  methods: {
    getImgUrl: function (img) {
      return require("@/assets/" + img);
    },
  },
  props: {
    item: {
      top: Number,
      left: Number,
      width: Number,
      height: Number,
      projectName: String,
      redirect: String,
      img: String,
    },
    show_more: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    getTop() {
      return `min(${((this.item.top / 1440) * 100).toFixed(2)}vw,${
        this.item.top
      }px)`;
    },
    getLeft() {
      if (this.item.left > 0)
        return `min(${((this.item.left / 1440) * 100).toFixed(2)}vw,${
          this.item.left
        }px)`;
      return `max(${((this.item.left / 1440) * 100).toFixed(2)}vw,${
        this.item.left
      }px)`;
    },
    getWidth() {
      return `min(${((this.item.width / 1440) * 100).toFixed(2)}vw,${
        this.item.width
      }px)`;
    },
    getHeight() {
      return `min(${((this.item.height / 1440) * 100).toFixed(2)}vw,${
        this.item.height
      }px)`;
    },
  },
};
</script>

<style scoped>
.overlay .round-button {
  visibility: hidden;
}
.overlay p {
  visibility: hidden;
}
.overlay:hover {
  visibility: visible;
  background: rgba(0, 33, 50, 0.6);
}

.overlay:hover .round-button {
  visibility: visible;
}

.overlay:hover p {
  visibility: visible;
}
</style>
