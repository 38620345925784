<template>
  <Header />
  <router-view />
</template>

<script lang="ts">
import Header from "@/components/Header.vue";

import { useHead } from "@vueuse/head";
import { defineComponent } from "vue";
import getSiteMeta from "./utils/getSiteMeta";

export default defineComponent({
  name: "HomeView",

  components: {
    Header,
  },

  pathname: "",

  setup() {
    const meta = {
      title: "Squeeze out innovations | Blue Lemons",
      description:
        "We create digital products that drive profit, ability and growth. We create products that people love to use.",
      url: "http://bluelemons.sk/",
    };

    useHead({
      title: meta.title,
      meta: getSiteMeta(meta),
    });
  },
});
</script>

<style></style>
