<template>
  <div class="pt-[76px] md:pt-[100px] overflow-hidden">
    <FloatingButton />
    <slot></slot>
  </div>
  <Footer :show_drop="show_drop" />
</template>

<script>
import Footer from "@/components/shared/Footer.vue";
import { defineComponent } from "vue";
import FloatingButton from "@/components/buttons/FloatingButton";

export default defineComponent({
  name: "PageLayout",
  props: {
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    show_drop: Boolean,
  },
  components: {
    Footer,
    FloatingButton,
  },
  mounted() {
    window.scrollTo(0, 0);
  },
});
</script>

<style scoped></style>
