<template>
  <div class="flex flex-col justify-items-center" ref="number">
    <div
      class="text-[60px] lg:text-[120px] text-lemonblue font-black montserrat relative transition ease-in duration-[1000ms] flex justify-center m-0"
      :class="{
        'text-[105px] leading-[108px] lg:text-[200px] lg:leading-[200px]': big,
        'add-margin': end && plus,
      }"
    >
      {{ actualValue }}
      <span
        v-if="plus && end"
        class="plus text-transparent ml-[8px] opacity-0 transition ease-in duration-[1000ms]"
        :class="end ? 'opacity-100 plus-animation' : ''"
        >+</span
      >
    </div>
    <h4 class="text-lemonblue">{{ subtitle }}</h4>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "NumberAdder",
  data: () => {
    return {
      actualValue: 0,
      counting: false,
      end: false,
    };
  },
  props: {
    value: {
      required: true,
      type: Number,
    },
    plus: {
      type: Boolean,
    },
    subtitle: {
      type: String,
    },
    start: {
      type: Boolean,
    },
    delay: {
      type: Number,
    },
    big: Boolean,
    autoplay: Boolean,
    offset: Number,
  },
  watch: {
    start: function (newVal) {
      if (newVal) {
        this.startCount();
      }
    },
  },
  methods: {
    async startCount() {
      await new Promise((res) => setTimeout(() => res("res"), this.delay));
      let time = Math.abs(70 - this.value);
      while (this.actualValue < this.value) {
        await new Promise((res) => setTimeout(() => res("res"), time));
        time += 1.3;
        this.actualValue++;
      }
      this.end = true;
    },
    checkScroll() {
      if (
        this.offset &&
        this.autoplay &&
        !this.counting &&
        window.scrollY + window.innerHeight >
          (this.$refs["number"] as HTMLDivElement).offsetTop + this.offset
      ) {
        this.startCount();
        this.counting = true;
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.checkScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.checkScroll);
  },
});
</script>

<style scoped>
.plus {
  font-family: sans-serif;
  -webkit-text-stroke: 2px #29adf2;
}
@keyframes popup {
  0% {
    transform: scale(0.9);
    transform: translateX(-90px);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
    transform: translateX(0px);
  }
}
.plus-animation {
  animation: popup;
  animation-duration: 1000ms;
  animation-fill-mode: forwards;
}
@keyframes move {
  from {
    @apply mr-[-60px];
  }
  to {
    @apply mr-0;
  }
}
.add-margin {
  animation: move;
  animation-duration: 1000ms;
  animation-fill-mode: forwards;
}
</style>
