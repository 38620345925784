<template>
  <svg
    viewBox="0 0 168 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    v-if="white"
    class="w-[168px] h-[28px] md:w-[270px] md:h-[45px]"
    :class="{ 'ease-in-animation': true }"
  >
    <path
      d="M48.2611 7.68359H54.1442C57.3168 7.68359 58.9051 8.75864 58.901 10.9128C58.901 11.7282 58.8119 12.357 58.6377 12.7992C58.4635 13.2414 58.1028 13.6592 57.5599 14.0527C58.192 14.3245 58.6296 14.6572 58.8686 15.0548C59.1077 15.4523 59.2292 16.1095 59.2292 17.0305C59.2292 19.4929 57.5599 20.7262 54.2172 20.7262H48.2529V7.68359H48.2611ZM53.7593 10.6572H52.7666V12.7465H53.7755C54.1645 12.7465 54.436 12.6613 54.59 12.4949C54.7439 12.3245 54.8209 12.0568 54.8209 11.6876C54.8209 11.3185 54.7358 11.0548 54.5697 10.9006C54.4036 10.7464 54.128 10.6613 53.7552 10.6613L53.7593 10.6572ZM53.8161 15.5578H52.7666V17.6957H53.8161C54.2699 17.6957 54.5737 17.6227 54.7277 17.4726C54.8817 17.3225 54.9627 17.0466 54.9627 16.6369C54.9627 16.2272 54.8776 15.9392 54.7115 15.7891C54.5454 15.6389 54.2456 15.5538 53.8201 15.5538L53.8161 15.5578Z"
      fill="white"
    />
    <path
      d="M68.7956 20.7099H60.0518V7.68359H64.5574V17.0385H68.7956V20.714V20.7099Z"
      fill="white"
    />
    <path
      d="M73.82 7.68359V16.2191C73.82 16.5882 73.9131 16.856 74.0991 17.0182C74.2858 17.1805 74.5975 17.2576 75.0432 17.2536C75.4854 17.2536 75.793 17.1765 75.9756 17.0182C76.1575 16.86 76.2546 16.6004 76.2546 16.2191V7.68359H80.7608V15.9676C80.7608 17.8093 80.3026 19.1034 79.3828 19.8458C78.4629 20.5883 77.0169 20.9614 75.0398 20.9574C73.0661 20.9574 71.6194 20.5923 70.6961 19.8661C69.7721 19.14 69.3183 17.8621 69.3183 16.0365V7.68359H73.82Z"
      fill="white"
    />
    <path
      d="M81.8424 20.7099V7.68359H91.5871V11.2779H86.3478V12.3895H90.4284V15.9473H86.3478V17.1197H91.5871V20.7099H81.8424Z"
      fill="white"
    />
    <path
      d="M105.149 20.7099H97.7455V7.25354H99.2408V19.3712H105.144V20.714L105.149 20.7099Z"
      fill="white"
    />
    <path
      d="M107.053 20.7099V7.25354H115.229V8.57607H108.548V13.2252H114.062V14.5274H108.548V19.3875H115.229V20.7099H107.053Z"
      fill="white"
    />
    <path
      d="M117.794 20.7099V7.25354H120.493L124.301 18.6288L128.11 7.25354H130.809V20.7099H129.314V8.84787H129.034L125.136 20.2232H123.467L119.56 8.84787H119.281V20.7099H117.79H117.794Z"
      fill="white"
    />
    <path
      d="M144.192 14.0811C144.192 16.4787 143.811 18.215 143.045 19.2941C142.279 20.3732 140.889 20.9087 138.876 20.9087C136.862 20.9087 135.472 20.357 134.706 19.2576C133.94 18.1541 133.556 16.4219 133.559 14.0568C133.559 11.6917 133.949 9.93505 134.726 8.78698C135.504 7.63891 136.886 7.06689 138.871 7.06689C140.853 7.06689 142.235 7.63891 143.021 8.77885C143.802 9.91886 144.195 11.6917 144.195 14.0893L144.192 14.0811ZM135.099 14.0406C135.099 16.0122 135.359 17.428 135.877 18.288C136.396 19.148 137.397 19.5821 138.88 19.5821C140.363 19.5821 141.359 19.1603 141.87 18.3164C142.381 17.4726 142.64 16.0609 142.635 14.077C142.635 12.0933 142.368 10.645 141.838 9.74037C141.307 8.83166 140.314 8.3813 138.855 8.3813C137.401 8.3813 136.408 8.83166 135.886 9.73224C135.363 10.6328 135.099 12.069 135.099 14.0406Z"
      fill="white"
    />
    <path
      d="M146.931 20.7099V7.25354H149.726L154.868 19.3875H155.277V7.25354H156.752V20.7099H154.013L148.786 8.57607H148.43V20.7099H146.931Z"
      fill="white"
    />
    <path
      d="M163.77 8.36509C161.825 8.36509 160.852 9.09934 160.852 10.5639C160.852 11.3793 161.075 11.9432 161.521 12.2474C161.967 12.5517 162.875 12.8559 164.24 13.1602C165.606 13.4645 166.574 13.8579 167.145 14.3448C167.716 14.8316 168 15.6308 168 16.7464C168 19.5091 166.57 20.8884 163.705 20.8884C162.514 20.8681 161.327 20.7546 160.152 20.5557L159.455 20.4584L159.609 19.2048C161.367 19.4402 162.708 19.5537 163.632 19.5537C165.549 19.5537 166.505 18.6531 166.505 16.8519C166.505 16.1257 166.294 15.5983 165.873 15.2859C165.452 14.9736 164.665 14.7018 163.511 14.4787C161.995 14.1541 160.922 13.7363 160.285 13.2251C159.649 12.714 159.333 11.8539 159.333 10.6491C159.333 8.23523 160.775 7.0304 163.665 7.0304C164.803 7.04665 165.937 7.14399 167.064 7.32248L167.724 7.41982L167.587 8.68149C165.792 8.46652 164.519 8.35696 163.766 8.35696L163.77 8.36509Z"
      fill="white"
    />
    <path
      d="M35.2741 12.5434C34.5231 15.9764 33.1404 20.0263 27.7245 23.1549C26.7397 23.7349 25.6842 24.1797 24.5855 24.4778C24.5695 24.0972 24.6193 23.7168 24.7325 23.3538C25.0782 22.6964 26.5682 18.3544 25.2769 13.6147C24.303 9.95488 22.27 6.68062 19.4438 4.22045C19.1934 4.00132 18.9193 3.77813 18.6292 3.55089C20.302 2.11842 23.1669 0.547989 25.6543 0.357264C28.2053 0.158424 28.9523 0.722482 29.7232 0.410018C30.2636 0.190888 30.8636 -0.117518 31.6026 0.0448006H31.6662C32.0146 0.139808 32.3414 0.30389 32.6278 0.527699C33.0095 0.794801 33.3296 1.1438 33.5655 1.55031C33.5953 1.60687 33.6218 1.66513 33.645 1.7248C34.0424 2.63784 33.6053 3.49408 34.4079 4.86973C34.9722 5.83553 35.9417 9.48364 35.2741 12.5434Z"
      fill="white"
    />
    <path
      d="M23.7098 22.9723C23.3958 23.5647 23.4634 25.0012 23.4833 25.3381C23.4833 25.4517 23.4435 26.7056 22.5535 27.367C21.6634 28.0285 20.9283 27.8743 19.7124 27.6186L19.3826 27.5497C18.8067 27.4952 18.2259 27.5433 17.6661 27.6917C15.8343 28.0285 12.7628 28.5966 8.58269 26.1781C2.7814 22.8221 1.1483 18.5531 0.250294 14.4465C-0.500695 11.0256 0.623801 7.0204 1.19201 6.08707C1.62571 5.3356 1.85609 4.47993 1.85956 3.60765C1.86117 3.21499 1.93252 2.82589 2.07015 2.45925C2.08984 2.40725 2.11377 2.35702 2.14167 2.3091C2.38387 1.90816 2.70611 1.56387 3.08736 1.29867C3.37087 1.074 3.69491 0.908537 4.041 0.811711H4.09265C4.22338 0.784227 4.35654 0.770628 4.49 0.771132C4.94293 0.797215 5.38612 0.915887 5.79331 1.12012L5.99993 1.20533C6.5244 1.37038 7.08219 1.39138 7.61714 1.2662C8.64721 1.11335 9.69085 1.07932 10.7284 1.16475C13.4303 1.37171 16.7363 3.16127 18.5998 4.81692C21.3089 7.17585 23.2563 10.3161 24.1866 13.8256C25.3945 18.261 24.0157 22.396 23.7098 22.9723Z"
      fill="white"
    />
    <path
      opacity="0.5"
      d="M14.1161 3.82605C15.6889 4.57282 17.1381 5.56541 18.4115 6.76808C19.2664 7.60141 20.0225 8.53437 20.6644 9.54779C20.7427 9.66929 20.7968 9.80535 20.8235 9.94819C20.8503 10.091 20.8493 10.2379 20.8205 10.3803C20.7917 10.5227 20.7358 10.658 20.6558 10.7783C20.5759 10.8987 20.4735 11.0018 20.3545 11.0817C20.2355 11.1616 20.1023 11.2169 19.9624 11.2442C19.8226 11.2716 19.6788 11.2705 19.5393 11.2411C19.3998 11.2117 19.2674 11.1546 19.1496 11.0729C19.0317 10.9913 18.9308 10.8867 18.8525 10.7652C18.6181 10.404 18.4552 9.99417 18.2048 9.62895C17.9545 9.26373 17.7002 8.88228 17.4101 8.52518C16.8498 7.81902 16.2229 7.1707 15.5386 6.58953C14.8708 6.03835 14.1642 5.53788 13.4247 5.09214C13.2598 5.00012 13.1374 4.84496 13.0845 4.6608C13.0316 4.47663 13.0525 4.27854 13.1426 4.11011C13.2327 3.94168 13.3847 3.8167 13.565 3.76267C13.7453 3.70864 13.9393 3.72998 14.1042 3.822L14.1161 3.82605Z"
      fill="#29ADF2"
    />
    <g opacity="0.5">
      <path
        opacity="0.5"
        d="M12.2065 4.46399C12.1509 4.45482 12.0963 4.43986 12.0436 4.41935C11.4115 4.15435 10.7655 3.9254 10.1085 3.73355C9.92729 3.67974 9.77438 3.5546 9.68347 3.38565C9.59256 3.2167 9.57109 3.0178 9.62378 2.83268C9.67647 2.64757 9.79901 2.49141 9.96444 2.39857C10.1299 2.30573 10.3246 2.2838 10.5059 2.33761C11.2136 2.54592 11.9099 2.79245 12.592 3.07616C12.7527 3.14455 12.8832 3.27074 12.9589 3.43084C13.0346 3.59093 13.0501 3.77381 13.0026 3.94484C12.9551 4.11588 12.8479 4.26318 12.7012 4.35886C12.5544 4.45453 12.3785 4.49194 12.2065 4.46399Z"
        fill="#29ADF2"
      />
    </g>
  </svg>

  <svg
    viewBox="0 0 168 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    v-else
    class="w-[168px] h-[28px] md:w-[270px] md:h-[45px]"
  >
    <path
      d="M48.301 7.68359H54.189C57.3642 7.68359 58.9538 8.75864 58.9497 10.9128C58.9497 11.7282 58.8605 12.357 58.6862 12.7992C58.5118 13.2414 58.1509 13.6592 57.6075 14.0527C58.2401 14.3245 58.6781 14.6572 58.9173 15.0548C59.1566 15.4523 59.2782 16.1095 59.2782 17.0305C59.2782 19.4929 57.6075 20.7262 54.262 20.7262H48.2928V7.68359H48.301ZM53.8038 10.6572H52.8103V12.7465H53.82C54.2093 12.7465 54.481 12.6613 54.6351 12.4949C54.7892 12.3245 54.8662 12.0568 54.8662 11.6876C54.8662 11.3185 54.7811 11.0548 54.6148 10.9006C54.4486 10.7464 54.1728 10.6613 53.7997 10.6613L53.8038 10.6572ZM53.8606 15.5578H52.8103V17.6957H53.8606C54.3148 17.6957 54.6189 17.6227 54.773 17.4726C54.9271 17.3225 55.0082 17.0466 55.0082 16.6369C55.0082 16.2272 54.923 15.9392 54.7568 15.7891C54.5905 15.6389 54.2904 15.5538 53.8646 15.5538L53.8606 15.5578Z"
      fill="#29ADF2"
    />
    <path
      d="M68.8525 20.7099H60.1015V7.68359H64.6108V17.0385H68.8525V20.714V20.7099Z"
      fill="#29ADF2"
    />
    <path
      d="M73.8811 7.68359V16.2191C73.8811 16.5882 73.9742 16.856 74.1604 17.0182C74.3473 17.1805 74.6593 17.2576 75.1053 17.2536C75.5479 17.2536 75.8557 17.1765 76.0384 17.0182C76.2205 16.86 76.3177 16.6004 76.3177 16.2191V7.68359H80.8276V15.9676C80.8276 17.8093 80.369 19.1034 79.4485 19.8458C78.5279 20.5883 77.0806 20.9614 75.1019 20.9574C73.1266 20.9574 71.6786 20.5923 70.7546 19.8661C69.8298 19.14 69.3756 17.8621 69.3756 16.0365V7.68359H73.8811Z"
      fill="#29ADF2"
    />
    <path
      d="M81.9101 20.7099V7.68359H91.6628V11.2779H86.4193V12.3895H90.5032V15.9473H86.4193V17.1197H91.6628V20.7099H81.9101Z"
      fill="#29ADF2"
    />
    <path
      d="M105.236 20.7099H97.8263V7.25354H99.3229V19.3712H105.231V20.714L105.236 20.7099Z"
      fill="#29ADF2"
    />
    <path
      d="M107.141 20.7099V7.25354H115.325V8.57607H108.638V13.2252H114.157V14.5274H108.638V19.3875H115.325V20.7099H107.141Z"
      fill="#29ADF2"
    />
    <path
      d="M117.892 20.7099V7.25354H120.592L124.404 18.6288L128.216 7.25354H130.917V20.7099H129.42V8.84787H129.14L125.239 20.2232H123.569L119.659 8.84787H119.38V20.7099H117.888H117.892Z"
      fill="#29ADF2"
    />
    <path
      d="M144.311 14.0811C144.311 16.4787 143.93 18.215 143.163 19.2941C142.397 20.3732 141.006 20.9087 138.99 20.9087C136.975 20.9087 135.584 20.357 134.818 19.2576C134.051 18.1541 133.666 16.4219 133.67 14.0568C133.67 11.6917 134.06 9.93505 134.838 8.78698C135.617 7.63891 136.999 7.06689 138.986 7.06689C140.969 7.06689 142.353 7.63891 143.139 8.77885C143.921 9.91886 144.315 11.6917 144.315 14.0893L144.311 14.0811ZM135.211 14.0406C135.211 16.0122 135.471 17.428 135.99 18.288C136.509 19.148 137.511 19.5821 138.995 19.5821C140.479 19.5821 141.476 19.1603 141.987 18.3164C142.498 17.4726 142.758 16.0609 142.753 14.077C142.753 12.0933 142.486 10.645 141.955 9.74037C141.424 8.83166 140.43 8.3813 138.97 8.3813C137.515 8.3813 136.521 8.83166 135.998 9.73224C135.475 10.6328 135.211 12.069 135.211 14.0406Z"
      fill="#29ADF2"
    />
    <path
      d="M147.052 20.7099V7.25354H149.85L154.996 19.3875H155.406V7.25354H156.882V20.7099H154.14L148.909 8.57607H148.553V20.7099H147.052Z"
      fill="#29ADF2"
    />
    <path
      d="M163.906 8.36509C161.959 8.36509 160.985 9.09934 160.985 10.5639C160.985 11.3793 161.208 11.9432 161.654 12.2474C162.101 12.5517 163.009 12.8559 164.376 13.1602C165.743 13.4645 166.712 13.8579 167.283 14.3448C167.855 14.8316 168.139 15.6308 168.139 16.7464C168.139 19.5091 166.708 20.8884 163.84 20.8884C162.648 20.8681 161.46 20.7546 160.284 20.5557L159.587 20.4584L159.741 19.2048C161.501 19.4402 162.843 19.5537 163.767 19.5537C165.686 19.5537 166.642 18.6531 166.642 16.8519C166.642 16.1257 166.432 15.5983 166.01 15.2859C165.588 14.9736 164.801 14.7018 163.646 14.4787C162.129 14.1541 161.055 13.7363 160.418 13.2251C159.781 12.714 159.465 11.8539 159.465 10.6491C159.465 8.23523 160.908 7.0304 163.8 7.0304C164.939 7.04665 166.075 7.14399 167.202 7.32248L167.863 7.41982L167.726 8.68149C165.929 8.46652 164.655 8.35696 163.901 8.35696L163.906 8.36509Z"
      fill="#29ADF2"
    />
    <path
      d="M35.3033 12.5434C34.5517 15.9764 33.1678 20.0263 27.7475 23.1549C26.7618 23.7349 25.7055 24.1797 24.6058 24.4778C24.5898 24.0972 24.6396 23.7168 24.7529 23.3538C25.0989 22.6964 26.5902 18.3544 25.2978 13.6147C24.3231 9.95488 22.2884 6.68062 19.4599 4.22045C19.2093 4.00132 18.9349 3.77813 18.6446 3.55089C20.3188 2.11842 23.1861 0.547989 25.6756 0.357264C28.2286 0.158424 28.9763 0.722482 29.7478 0.410018C30.2886 0.190888 30.8891 -0.117518 31.6288 0.0448006H31.6924C32.0411 0.139808 32.3681 0.30389 32.6548 0.527699C33.0368 0.794801 33.3571 1.1438 33.5933 1.55031C33.6231 1.60687 33.6496 1.66513 33.6728 1.7248C34.0705 2.63784 33.6331 3.49408 34.4364 4.86973C35.0011 5.83553 35.9714 9.48364 35.3033 12.5434Z"
      fill="#29ADF2"
    />
    <path
      d="M23.7294 22.9723C23.4152 23.5647 23.4828 25.0012 23.5027 25.3381C23.5027 25.4517 23.4629 26.7056 22.5721 27.367C21.6813 28.0285 20.9456 27.8743 19.7287 27.6186L19.3987 27.5497C18.8223 27.4952 18.241 27.5433 17.6807 27.6917C15.8474 28.0285 12.7734 28.5966 8.58979 26.1781C2.7837 22.8221 1.14925 18.5531 0.250501 14.4465C-0.501109 11.0256 0.624317 7.0204 1.193 6.08707C1.62705 5.3356 1.85762 4.47993 1.86109 3.60765C1.86271 3.21499 1.93412 2.82589 2.07186 2.45925C2.09157 2.40725 2.11552 2.35702 2.14344 2.3091C2.38584 1.90816 2.70834 1.56387 3.08992 1.29867C3.37365 1.074 3.69796 0.908537 4.04434 0.811711H4.09604C4.22687 0.784227 4.36014 0.770628 4.49372 0.771132C4.94702 0.797215 5.39057 0.915887 5.7981 1.12012L6.00489 1.20533C6.52979 1.37038 7.08805 1.39138 7.62344 1.2662C8.65436 1.11335 9.69887 1.07932 10.7372 1.16475C13.4415 1.37171 16.7501 3.16127 18.6152 4.81692C21.3265 7.17585 23.2756 10.3161 24.2066 13.8256C25.4155 18.261 24.0356 22.396 23.7294 22.9723Z"
      fill="#29ADF2"
    />
    <path
      opacity="0.5"
      d="M14.1278 3.82605C15.7019 4.57282 17.1522 5.56541 18.4267 6.76808C19.2823 7.60141 20.0391 8.53437 20.6815 9.54779C20.7598 9.66929 20.8139 9.80535 20.8407 9.94819C20.8676 10.091 20.8665 10.2379 20.8377 10.3803C20.8089 10.5227 20.7529 10.658 20.6729 10.7783C20.5928 10.8987 20.4904 11.0018 20.3713 11.0817C20.2522 11.1616 20.1189 11.2169 19.9789 11.2442C19.8389 11.2716 19.695 11.2705 19.5555 11.2411C19.4159 11.2117 19.2833 11.1546 19.1654 11.0729C19.0474 10.9913 18.9464 10.8867 18.8681 10.7652C18.6335 10.404 18.4704 9.99417 18.2199 9.62895C17.9693 9.26373 17.7148 8.88228 17.4245 8.52518C16.8637 7.81902 16.2363 7.1707 15.5515 6.58953C14.883 6.03835 14.1759 5.53788 13.4358 5.09214C13.2708 5.00012 13.1483 4.84496 13.0953 4.6608C13.0424 4.47663 13.0633 4.27854 13.1535 4.11011C13.2437 3.94168 13.3957 3.8167 13.5762 3.76267C13.7567 3.70864 13.9508 3.72998 14.1159 3.822L14.1278 3.82605Z"
      fill="#F9F9F9"
    />
    <g opacity="0.5">
      <path
        opacity="0.5"
        d="M12.2166 4.46399C12.1609 4.45482 12.1063 4.43986 12.0536 4.41935C11.421 4.15435 10.7744 3.9254 10.1169 3.73355C9.9355 3.67974 9.78247 3.5546 9.69148 3.38565C9.60049 3.2167 9.57901 3.0178 9.63174 2.83268C9.68448 2.64757 9.80712 2.49141 9.97268 2.39857C10.1382 2.30573 10.3332 2.2838 10.5146 2.33761C11.2228 2.54592 11.9198 2.79245 12.6024 3.07616C12.7632 3.14455 12.8939 3.27074 12.9696 3.43084C13.0453 3.59093 13.0609 3.77381 13.0134 3.94484C12.9658 4.11588 12.8585 4.26318 12.7117 4.35886C12.5648 4.45453 12.3887 4.49194 12.2166 4.46399Z"
        fill="#F9F9F9"
      />
    </g>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "BlueLemonsLogo",
  props: {
    white: {
      type: Boolean,
      default: false,
    },
    ease: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style scoped>
@keyframes ease-in {
  50% {
    transform: translateY(-200px);
  }
  60% {
    transform: translateY(-6px) rotate(5deg);
  }
  80% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.ease-in-animation {
  animation: ease-in;
  animation-duration: 800ms;
}
</style>
