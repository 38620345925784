<template>
  <nav class="fixed max-h-[100px] z-[200] w-full bg-background">
    <div
      class="max-width-limit flex justify-between items-fit py-[20px] overflow-hidden"
    >
      <div
        class="flex items-center justify-between h-[30px] md:h-[40px] relative"
      >
        <router-link to="/" class="mt-[15px] xl:mt-0">
          <img
            alt="blue lemons logo"
            src="@/assets/animations/logo/LOGO_4_Full.gif"
            class="image hidden"
            :class="{ '!block': logo === 1 }"
          />
          <img
            alt="blue lemons logo"
            src="@/assets/animations/logo/LOGO_5_Full.gif"
            class="image hidden"
            :class="{ '!block': logo === 2 }"
          />
          <img
            alt="blue lemons logo"
            src="@/assets/animations/logo/LOGO_7_Full.gif"
            class="image hidden"
            :class="{ '!block': logo === 4 }"
          />
          <img
            alt="blue lemons logo"
            src="@/assets/animations/logo/base.jpg"
            class="image hidden"
            :class="{ '!block': logo === 0 }"
          />
        </router-link>
      </div>

      <div class="lg:flex flex-row items-center space-x-6 hidden">
        <router-link class="nav-button mt-[-15px] pointer-events-none" to="/">
          <p class="w-[80px]"></p>
          <div />
        </router-link>
        <router-link class="nav-button" to="/work">
          <p>Work</p>
          <div />
        </router-link>
        <router-link class="nav-button" to="/clients">
          <p>Clients</p>
          <div />
        </router-link>
        <router-link class="nav-button" to="/services">
          <p>Services</p>
          <div />
        </router-link>
        <router-link class="nav-button" to="/about">
          <p>About</p>
          <div />
        </router-link>

        <div>
          <router-link to="/contact">
            <button class="button lg:ml-6 xl:ml-12">Contact</button>
          </router-link>
        </div>
      </div>

      <div @click="showMenu = !showMenu" class="lg:hidden z-50 h-[36px]">
        <button
          type="button"
          class="text-gray-800 hover:text-gray-400 focus:outline-none focus:text-gray-400"
        >
          <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            class="stroke-lemonblue"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.5 25.5L19.5 25.5"
              stroke-width="2.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M7.5 18H28.5"
              stroke-width="2.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M16.5 10.5L28.5 10.5"
              stroke-width="2.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>
  </nav>

  <div
    class="max-width-limit mobile-menu z-[250] top-[-120vh] w-full flex flex-col items-center pt-[0px] gap-y-[32px] fixed bg-lemonblue text-white pb-[40px] transition ease-in duration-[200ms]"
    :class="{ 'mobile-menu-open': showMenu }"
  >
    <div
      class="max-h-[100px] w-full flex justify-between items-center py-[20px]"
    >
      <div class="flex items-center justify-between">
        <router-link to="/"
          ><Logo
            v-if="showMenu"
            :white="true"
            :ease="true"
            @click="showMenu = !showMenu"
        /></router-link>
      </div>

      <div @click="showMenu = !showMenu" class="lg:hidden z-50 h-[36px]">
        <button
          type="button"
          class="text-gray-800 hover:text-gray-400 focus:outline-none focus:text-gray-400"
        >
          <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            :class="showMenu ? 'stroke-background' : 'stroke-lemonblue'"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.5 25.5L19.5 25.5"
              stroke-width="2.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M7.5 18H28.5"
              stroke-width="2.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M16.5 10.5L28.5 10.5"
              stroke-width="2.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>

    <div class="flex flex-col justify-center ml-[-50px]">
      <router-link
        class="nav-button-mobile"
        @click="showMenu = !showMenu"
        to="/work"
        >Work
      </router-link>
      <router-link
        class="nav-button-mobile"
        @click="showMenu = !showMenu"
        to="/clients"
      >
        Clients
      </router-link>
      <router-link
        class="nav-button-mobile"
        @click="showMenu = !showMenu"
        to="/services"
      >
        Services
      </router-link>
      <router-link
        class="nav-button-mobile"
        @click="showMenu = !showMenu"
        to="/about"
        >About
      </router-link>
      <router-link
        to="/contact"
        @click="showMenu = !showMenu"
        class="nav-button-mobile"
        >Contact
      </router-link>

      <div class="flex flex-row justify-between mt-[35px] w-[150px]">
        <a
          v-for="icon in icons"
          :key="icon.title"
          :href="icon.link"
          target="_blank"
        >
          <img
            class="w-[16px] h-[16px] cursor-pointer hover:scale-150 transition ease-in duration-[100ms]"
            :title="icon.title"
            :alt="icon.alt"
            :src="icon.path"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from "@/components/Logo.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "HeaderComponent",
  data: () => {
    return {
      showMenu: false,
      logo: 1,
      icons: [
        {
          path: require("@/assets/footer/Instagram_icon.png"),
          alt: "Instagram Icon",
          title: "Instagram Icon",
          link: "https://www.instagram.com/bluelemons_sk/",
        },
        {
          path: require("@/assets/footer/Facebook_icon.png"),
          alt: "Facebook Icon",
          title: "Facebook Icon",
          link: "https://www.facebook.com/BlueLemonsSK/",
        },
        {
          path: require("@/assets/footer/Twitter_icon.png"),
          alt: "Twitter Icon",
          title: "Twitter Icon",
          link: "https://twitter.com/BlueLemons_sk",
        },
        {
          path: require("@/assets/footer/LinkedIn_icon.png"),
          alt: "LinkedIn Icon",
          title: "LinkedIn Icon",
          link: "https://www.linkedin.com/company/bluelemonssk/",
        },
      ],
    };
  },
  methods: {
    checkWidth() {
      if (window.innerWidth > 1024) this.showMenu = false;
    },
    scrollToContact() {
      this.showMenu = false;
      const el = document.getElementById("contact");
      if (el) {
        el.scrollIntoView({ block: "center", behavior: "smooth" });
      }
    },
    async animate() {
      this.logo = 0;
      await new Promise((resolve) => setTimeout(resolve, 15000));
      this.logo = 1;
      await new Promise((resolve) => setTimeout(resolve, 2000));
      this.logo = 0;
      await new Promise((resolve) => setTimeout(resolve, 15000));
      this.logo = 2;
      await new Promise((resolve) => setTimeout(resolve, 2000));
      // this.logo = 0;
      // await new Promise((resolve) => setTimeout(resolve, 10000));
      // this.logo = 3;
      await new Promise((resolve) => setTimeout(resolve, 2000));
      this.logo = 0;
      await new Promise((resolve) => setTimeout(resolve, 15000));
      this.logo = 4;
      await new Promise((resolve) => setTimeout(resolve, 2000));
      await this.animate();
    },
  },
  computed: {
    getLogoNum() {
      return this.logo;
    },
  },
  mounted() {
    this.animate();
    window.addEventListener("resize", this.checkWidth);
  },
  unmounted() {
    window.removeEventListener("resize", this.checkWidth);
  },
  components: {
    Logo,
  },
});
</script>

<style scoped>
.nav-button {
  overflow: hidden;
  @apply mb-[-8px];
}

.nav-button p {
  font-family: "Montserrat", serif;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.005em;

  @apply px-[16px];
}

.nav-button div {
  transform: translateX(calc(-100% - 5px));
  @apply w-full h-[1px] mt-[8px] bg-lemonblue transition ease-in-out duration-500;
}

.nav-button:hover {
  font-weight: 500;
  color: black;
}

.nav-button:focus,
.nav-button.router-link-active {
  font-weight: 500;
  color: black;
  @apply border-lemonblue;
}

.nav-button:focus div,
.nav-button.router-link-active div {
  transform: translateX(0);
}

.nav-button-mobile {
  font-family: "Montserrat", serif;
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  line-height: 39px;
  text-align: left;
  margin-bottom: 32px;
}

.nav-button-mobile:last-child {
  font-weight: 400;
}

.mobile-menu {
  height: 120vh;
  transform: translateY(0);
}

.mobile-menu-open {
  transform: translateY(120vh);
}
.image {
  @apply w-[382px] xl:w-[382px] mt-[-12px] xl:mt-[6px] h-[60px] xl:h-[98px] ml-[-107px] xl:ml-[-56px] max-w-[200%] overflow-visible object-contain;
}
</style>
