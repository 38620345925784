<template>
  <div class="w-full flex flex-col items-center">
    <div class="max-w-[1440px] xl:px-[130px] md:px-[64px] px-[16px] mx-auto">
      <div
        class="text-[#29adf2] montserrat text-left lg:text-[91px] md:text-[70px] xs:text-[40px] font-black pb-[32px] md:pb-[48x]"
      >
        {{ name }}
      </div>
      <div class="grid grid lg:grid-cols-12 xs:grid-cols-1">
        <div
          class="lg:pr-[24px] relative pb-[24px] lg:col-span-7 text-left text-[#29adf2] montserrat font-light xs:text-[24px] xs:leading-[33px] lg:text-[34px] lg:leading-[42px] lg:border-r-solid lg:border-r-[1px] lg:border-r-[#29adf2]"
        >
          <span
            class="inline-block h-[1px] w-[70px] bg-[#29adf2] mb-[0.3em] mr-[16px]"
          />
          <div
            class="custom-border-bottom absolute left-[10%] right-[10%] bottom-0 border-b-solid border-b-[1px] border-b-[#29adf2] lg:border-b-[0px]"
          ></div>
          {{ blue_text }}
        </div>
        <div
          class="lg:pl-[24px] pt-[24px] lg:col-span-5 text-left text-[#002132] lg:text-[20px] lg:leading-[28px] lg:text-[16px] lg:leading-[24px] font-regular"
        >
          {{ black_text }}
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";
import LineAnimation from "@/components/shared/LineAnimation.vue";

@Options({
  props: {
    name: String,
    blue_text: String,
    black_text: String,
  },
  components: {
    LineAnimation,
  },
})
export default class DetailComponent extends Vue {}
</script>
