<template>
  <carousel
    :touchDrag="true"
    :autoplay="autoplay"
    :items-to-show="1.6"
    :wrap-around="infinity"
    class="w-full slider-home"
    @mouseover="hoverArrows"
    @mouseleave="unhoverArrows"
  >
    <slide
      v-for="(slide, _, index) in slides"
      :key="index"
      @touchmove="onTouch"
    >
      <div class="carousel__item">
        <img
          alt="image of slide"
          :src="windowWidth > 600 ? slide.path : slide.pathMobile"
          class="slide-image"
        />
      </div>
      <div
        v-show="slide.last !== 'true' && hover"
        @click="
          $router.push({ name: 'project-detail', params: { id: slide.link } })
        "
        class="overlay w-full h-full flex flex-col justify-center items-center rounded-[16px] m-[25x]"
      >
        <a class="round-button my-[40px]">Show more</a>
        <p class="text-white max-w-[300px]">{{ slide.app }}</p>
      </div>
      <div v-show="slide.last == 'true'" class="absolute">
        <router-link to="/work">
          <div class="vm">
            <div>View</div>
            <div class="vms">More</div>
          </div>
          <video
            v-show="!iphone"
            style="cursor: pointer"
            id="videoId"
            autoplay
            preload
            muted
            loop
            class="xs:w-[128px] sm:w-[128px] md:w-[144px] lg:w-[256px] xl:w-[512] cursor-pointer mx-auto apple-fix"
            playsinline
          >
            <source :src="eye" type="video/mp4" />
          </video>
          <img v-show="iphone" :src="eyeImage" />
        </router-link>
      </div>
    </slide>

    <template #addons>
      <navigation />
      <pagination />
    </template>
  </carousel>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";

export default {
  name: "Slider-component",
  props: {
    slides: { required: true },
    infinity: { default: true },
    autoplay: { default: 5000 },
    hover: { default: true },
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data: () => {
    return {
      hoverViewMore: false,
      bullet: require("@/assets/carousel/bullet.png"),
      bulletActive: require("@/assets/carousel/bullet-active.png"),
      viewMoreDefault: require("@/assets/carousel/view-more-default.png"),
      viewMoreHover: require("@/assets/carousel/view-more-hover.png"),
      eye: require("@/assets/animations/eyeB.mp4"),
      eyeImage: require("@/assets/carousel/eyeB.png"),
      windowWidth: window.innerWidth,
      iphone: false,
    };
  },
  mounted() {
    this.checkIfIos();
  },
  methods: {
    checkIfIos() {
      if (navigator.userAgent.toLowerCase().indexOf("iphone") > -1) {
        this.iphone = true;
      } else {
        this.iphone = false;
      }
    },
    onTouch(e) {
      e.preventDefault();
    },
    hoverArrows() {
      const prev = document.getElementsByClassName("carousel__prev");
      prev[0].classList.add("arrow-hover-prev");
      const next = document.getElementsByClassName("carousel__next");
      next[0].classList.add("arrow-hover-next");
    },
    unhoverArrows() {
      const prev = document.getElementsByClassName("carousel__prev");
      prev[0].classList.remove("arrow-hover-prev");
      const next = document.getElementsByClassName("carousel__next");
      next[0].classList.remove("arrow-hover-next");
    },
  },
};
</script>

<style>
.vm {
  color: #eaf7fe;
  cursor: pointer;
  font-size: 40px;
  line-height: 40px;
  font-weight: 900;
  -webkit-text-stroke: 1px #29adf2;
}
.vm:hover {
  -webkit-text-stroke: unset;
  @apply text-lemonblue;
}
.overlay {
  position: absolute;
}
.overlay .round-button {
  visibility: hidden;
}
.overlay p {
  visibility: hidden;
}
.overlay:hover {
  visibility: visible;
  background: rgba(0, 33, 50, 0.6);
}

.overlay:hover .round-button {
  visibility: visible;
}

.overlay:hover p {
  visibility: visible;
}
.slider-home {
  touch-action: pan-y;
}
.slider-home .carousel__item {
  touch-action: pan-y;
  max-height: 1280px;
  width: 100%;
  color: var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-home .carousel__slide {
  touch-action: pan-y;
  padding: 10px;
}

.slider-home .carousel__icon {
  display: none;
}

.slider-home .carousel__prev {
  right: auto;
  left: 12em;
  background-image: url("@/assets/carousel/left-default.png") !important;
  width: 64px;
  height: 64px;
  background-size: cover;
  background-color: transparent;
  transform: translate3d(-50%, -50%, 500px);
}

.slider-home .carousel__next {
  right: 12em;
  left: auto;
  background-image: url("@/assets/carousel/right-default.png") !important;
  width: 64px;
  height: 64px;
  background-size: cover;
  background-color: transparent;
  transform: translate3d(50%, -50%, 500px);
}

.slider-home .arrow-hover-prev {
  background-image: url("@/assets/carousel/left-hover.png") !important;
}

.slider-home .arrow-hover-next {
  background-image: url("@/assets/carousel/right-hover.png") !important;
}

.slider-home .carousel__pagination-button {
  width: 100%;
  background-color: #e5e9ea;
  cursor: pointer;
}

.slider-home .carousel__pagination-button--active {
  background-color: #29adf2;
}
.slider-home .carousel__pagination {
  position: absolute;
  left: 25%;
  width: 50%;
}
.slider-home .carousel__pagination-item {
  width: 100%;
}

.slider-home .slide-image {
  width: 100%;
}

.slider-home .carousel__prev--in-active {
  display: none;
}

.footer {
  position: absolute;
  left: 5%;
  top: 75%;
}
.list-line {
  position: relative;
  padding-left: 35px;
}
.list-line:before {
  content: "";
  display: block;
  width: 24px;
  height: 1px;
  background: #29adf2;
  left: 0;
  top: 50%;
  position: absolute;
}

@media only screen and (max-width: 600px) {
  .footer {
    top: 80%;
  }
  .slider-home .carousel__prev {
    display: none;
  }
  .slider-home .carousel__next {
    display: none;
  }
  .overlay {
    display: none;
    visibility: visible;
  }
}
@media only screen and (min-width: 600px) {
  .footer {
    top: 80%;
  }
  .slider-home .carousel__prev {
    right: auto;
    left: 6em;
    width: 48px;
    height: 48px;
  }
  .slider-home .carousel__next {
    right: 6em;
    left: auto;
    width: 48px;
    height: 48px;
  }
}
@media only screen and (min-width: 1024px) {
  .footer {
    top: 80%;
  }
  .slider-home .carousel__prev {
    right: auto;
    left: 8em;
    width: 64px;
    height: 64px;
  }
  .slider-home .carousel__next {
    right: 8em;
    left: auto;
    width: 64px;
    height: 64px;
  }
}
@media only screen and (min-width: 1440px) {
  .footer {
    top: 80%;
  }
  .slider-home .carousel__prev {
    right: auto;
    left: 12em;
    width: 96px;
    height: 96px;
  }
  .slider-home .carousel__next {
    right: 12em;
    left: auto;
    width: 96px;
    height: 96px;
  }
}
@media only screen and (min-width: 1600px) {
  .vm {
    font-size: 60px;
  }
  .vms {
    margin: 15px;
  }
  .footer {
    top: 85%;
  }
  .slider-home .carousel__prev {
    right: auto;
    left: 14em;
    width: 96px;
    height: 96px;
  }
  .slider-home .carousel__next {
    right: 14em;
    left: auto;
    width: 96px;
    height: 96px;
  }
}
@media only screen and (min-width: 2048px) {
  .vm {
    font-size: 80px;
  }
  .vms {
    margin: 25px;
  }
  .footer {
    top: 90%;
  }
  .slider-home .carousel__prev {
    right: auto;
    left: 22em;
    width: 96px;
    height: 96px;
  }
  .slider-home .carousel__next {
    right: 22em;
    left: auto;
    width: 96px;
    height: 96px;
  }
}
</style>
