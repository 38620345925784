import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { VueperSlides, VueperSlide } from "vueperslides";
import "./styles/app.css"; // Here
import { createHead } from "@vueuse/head";

const head = createHead();

createApp(App)
  .use(store)
  .component("VueperSlides", VueperSlides)
  .component("VueperSlide", VueperSlide)
  .use(router)
  .use(head)
  .mount("#app");
