import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7a70f02c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "flex flex-col justify-items-center",
  ref: "number"
}
const _hoisted_2 = { class: "text-lemonblue" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["text-[60px] lg:text-[120px] text-lemonblue font-black montserrat relative transition ease-in duration-[1000ms] flex justify-center m-0", {
        'text-[105px] leading-[108px] lg:text-[200px] lg:leading-[200px]': _ctx.big,
        'add-margin': _ctx.end && _ctx.plus,
      }])
    }, [
      _createTextVNode(_toDisplayString(_ctx.actualValue) + " ", 1),
      (_ctx.plus && _ctx.end)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: _normalizeClass(["plus text-transparent ml-[8px] opacity-0 transition ease-in duration-[1000ms]", _ctx.end ? 'opacity-100 plus-animation' : ''])
          }, "+", 2))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.subtitle), 1)
  ], 512))
}