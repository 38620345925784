<template>
  <div class="w-full overflow-hidden">
    <div
      class="h-full max-w-[1440px] xl:px-[130px] md:px-[64px] px-[16px] relative mx-auto"
    >
      <div
        v-if="selectedIndex != 0"
        class="md:block xs:hidden absolute top-[224px] cursor-pointer z-10 left xl:left-[130px] md:left-[64px] left-[16px]"
        @click="translateFunctionLeft()"
      ></div>
      <div
        v-if="slides && slides.length - 1 != selectedIndex"
        class="md:block xs:hidden absolute top-[224px] cursor-pointer z-10 right xl:right-[130px] md:right-[64px] right-[16px]"
        @click="translateFunctionRight()"
      ></div>
    </div>
    <div
      class="flex flex-nowrap justify-start items-center w-full relative md:duration-[500ms]"
      :class="sliding ? 'xs:duration-[0ms]' : 'xs:duration-[500ms]'"
      :style="'transform: translateX(' + translate + 'px)'"
      @touchstart="setStart(event)"
      @touchend="setEnd(event)"
      @touchmove="drag(event)"
    >
      <img
        v-for="(slide, index) in slides"
        :key="index"
        :src="
          checkWindowSize() && slide.img_mobile
            ? getImgUrl(slide.img_mobile)
            : getImgUrl(slide.img)
        "
        :ref="
          selectedIndex == index
            ? setItemRef
            : selectedIndex + 1 == index
            ? 'next'
            : selectedIndex - 1 == index
            ? 'previous'
            : 'imgg'
        "
        class="max-h-[544px] max-w-[70%] mx-[13px] shrink-0"
      />
    </div>
    <div
      class="h-[5px] max-w-[1440px] xl:px-[130px] md:px-[64px] px-[16px] relative mx-auto mt-[16px] md:mt-[43px]"
    >
      <div
        class="flex rounded-[3px] flex-row absolute md:left-[30%] left-[20%] md:w-[40%] w-[60%] bg-[#E5E9EA] h-[5px]"
      >
        <div
          v-for="(slide, index) in slides"
          :key="index"
          class="rounded-[12px] w-full h-full"
          :class="selectedIndex == index ? 'bg-[#29ADF2]' : 'bg-transarent'"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Slider-component",
  props: ["slides"],
  components: {},
  data: () => {
    return {
      start: 0,
      end: 0,
      difference: 0,
      selectedIndex: 0,
      translate: 0,
      first: true,
      sliding: false,
      img: null,
      originalTranslate: 0,
      hoverViewMore: false,
      bullet: require("@/assets/carousel/bullet.png"),
      bulletActive: require("@/assets/carousel/bullet-active.png"),
      viewMoreDefault: require("@/assets/carousel/view-more-default.png"),
      viewMoreHover: require("@/assets/carousel/view-more-hover.png"),
      eye: require("@/assets/animations/eyeB.mp4"),
    };
  },

  methods: {
    checkWindowSize() {
      return window.innerWidth < 768;
    },
    setItemRef(el) {
      if (this.first) {
        setTimeout(() => {
          if (window.innerWidth < 768) {
            this.translate = 0;
          } else {
            this.translate =
              window.innerWidth / 2 - (this.img.clientWidth + 13 * 2) / 2;
          }
        }, 1500);
      }
      this.first = false;
      this.img = el;
    },
    setStart() {
      this.originalTranslate = this.translate;
      this.start = event.touches[0].screenX;
      this.sliding = true;
    },
    setEnd() {
      this.translate = this.originalTranslate;
      this.sliding = false;

      if (
        this.difference > 50 &&
        this.slides.length - 1 !== this.selectedIndex
      ) {
        this.translateFunctionRight();
      } else if (this.difference < -50 && this.selectedIndex !== 0) {
        this.translateFunctionLeft();
      } else {
        this.translate = this.originalTranslate;
      }
      this.difference = 0;
    },
    translateFunctionRight() {
      const el = this.$refs.next;
      const img = this.img;

      this.selectedIndex = this.selectedIndex + 1;
      this.translate =
        this.translate -
        (el[0].clientWidth + 13 * 2) / 2 -
        (img.clientWidth + 13 * 2) / 2;
    },
    translateFunctionLeft() {
      const el = this.img;
      const previous = this.$refs.previous;

      this.selectedIndex = this.selectedIndex - 1;
      this.translate =
        this.translate +
        (el.clientWidth + 13 * 2) / 2 +
        (previous[0].clientWidth + 13 * 2) / 2;
    },
    getImgUrl: function (img) {
      return require("@/assets/" + img);
    },
    drag() {
      this.difference = this.start - event.touches[0].screenX;

      this.translate = this.originalTranslate - this.difference;
    },
  },
};
</script>

<style>
.left {
  height: 96px;
  width: 96px;
  background-image: url("@/assets/carousel/left-default.png");
  background-repeat: no-repeat;
}
.left:hover {
  background-image: url("@/assets/carousel/left-hover.png");
}
.right {
  height: 96px;
  width: 96px;
  background-image: url("@/assets/carousel/right-default.png");
  background-repeat: no-repeat;
}
.right:hover {
  background-image: url("@/assets/carousel/right-hover.png");
}
</style>
