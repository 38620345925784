<template>
  <div class="text-left">
    <div
      ref="line"
      class="montserrat xl:text-[34px] md:text-[27px] xs:text-[18px] transition ease-in duration-[1500ms] opacity-0 line-text"
      :class="showAnimation ? 'line-animation opacity-100' : 'line'"
    >
      <p
        class="xs:w-[100%] line-text"
        :style="'color: ' + text_color"
        :class="{
          'md:text-[48px] text-[30px]': big,
        }"
      >
        <span
          :class="showAnimation ? 'line-animation2' : 'line2'"
          :style="'background: ' + line_color"
        />{{ text }}
        <slot></slot>
      </p>
    </div>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    main_title: Boolean,
    text: String,
    line_color: String,
    text_color: String,
    small: Boolean,
    big: Boolean,
    delay: {
      type: Number,
      default: 500,
    },
  },
  data: () => {
    return {
      showAnimation: false,
    };
  },

  methods: {
    activate() {
      setTimeout(() => (this.showAnimation = true), this.delay);
    },
    isInViewport() {
      const el = this.$refs.line;
      if (el) {
        const rect = el.getBoundingClientRect();
        if (
          rect.top >= 0 &&
          rect.bottom <=
            (window.innerHeight - 100 ||
              document.documentElement.clientHeight - 100)
        ) {
          this.showAnimation = true;
        }
      } else {
        this.showAnimation = false;
      }
    },
  },
  mounted() {
    if (this.main_title) {
      this.activate();
    } else {
      document.addEventListener("scroll", this.isInViewport);
    }
  },
  unmounted() {
    document.removeEventListener("scroll", this.isInViewport);
  },
})
export default class LineAnimation extends Vue {}
</script>

<style scoped>
.line-text::first-line::after {
  color: orange;
  padding-inline-end: 20px;
  content: "→";
  width: 70px;
}
</style>
