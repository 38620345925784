<template>
  <swiper
    :direction="'vertical'"
    :pagination="{
      bulletClass: 'swiper-pagination-bullet',
      bulletActiveClass: 'swiper-pagination-bullet-active',
      clickable: true,
    }"
    :autoplay="{
      delay: 5000,
      disableOnInteraction: false,
    }"
    :allowTouchMove="false"
    :mousewheel="false"
    :speed="600"
    :modules="modules"
    @slideChange="onSlideChange"
    @swiper="onSwiper"
    class="mySwiper"
  >
    <swiper-slide
      class="max-w-[1440px] lg:pl-[130px] md:pl-[50px] pl-[16px] mx-auto"
    >
      <div
        class="relative w-full h-full text-lemonblue overflow-hidden text-left"
      >
        <h2 class="px-[16px] pt-[56px] relative z-10">WEB</h2>
        <h2 class="px-[16px] pb-[32px] relative z-10">APPLICATIONS</h2>

        <div class="relative z-10">
          <p v-show="windowWidth < 600" class="px-[16px]">
            Reliable and good lookig web applications that cover all your needs
            and reach your goals.
          </p>
        </div>
        <div v-show="windowWidth > 600" class="relative z-10 px-[16px]">
          <p>Reliable and good</p>
          <p>lookig web applications</p>
          <p>that cover all your needs</p>
          <p>and reach your goals.</p>
        </div>
        <div class="slider-tech-wrapper z-0 mt-[32px]">
          <img
            :src="first"
            :cover="true"
            class="xs:relative sm:absolute rounded-[16px]"
          />
        </div>
      </div>
    </swiper-slide>
    <swiper-slide
      class="max-w-[1440px] lg:pl-[130px] md:pl-[50px] pl-[16px] mx-auto"
    >
      <div
        class="relative w-full h-full text-lemonblue overflow-hidden text-left"
      >
        <h2 class="px-[16px] pt-[56px] relative z-10">MOBILE</h2>
        <h2 class="px-[16px] pb-[32px] relative z-10">APPLICATIONS</h2>
        <div class="relative z-10">
          <p v-show="windowWidth < 600" class="px-[16px]">
            Mobile solutions with functioning design, development, integrations,
            support and care.
          </p>
        </div>
        <div v-show="windowWidth > 600" class="relative z-10 px-[16px]">
          <p>Mobile solutions with</p>
          <p>functioning design,</p>
          <p>development,</p>
          <p>integrations, support and</p>
          <p>care.</p>
        </div>
        <div class="slider-tech-wrapper z-0 mt-[32px]">
          <img
            :src="second"
            :cover="true"
            class="xs:relative sm:absolute rounded-[16px]"
          />
        </div>
      </div>
    </swiper-slide>
    <swiper-slide
      class="max-w-[1440px] lg:pl-[130px] md:pl-[50px] pl-[16px] mx-auto"
    >
      <div
        class="relative w-full h-full text-lemonblue overflow-hidden text-left"
      >
        <h2 class="px-[16px] pt-[56px] relative z-10" style="opacity: 0">
          IOT
        </h2>
        <h2 class="px-[16px] pb-[32px] relative z-10">IOT & ML</h2>
        <div class="relative z-10">
          <p v-show="windowWidth < 600" class="px-[16px]">
            Digital systems and solutions for custom needs with all modern
            gadgets and creative values.
          </p>
        </div>
        <div v-show="windowWidth > 600" class="relative z-10 px-[16px]">
          <p>Digital systems and</p>
          <p>solutions for custom</p>
          <p>needs with all modern</p>
          <p>gadgets and creative</p>
          <p>values.</p>
        </div>
        <div class="slider-tech-wrapper z-0 mt-[32px]">
          <img
            :src="third"
            :cover="true"
            class="xs:relative sm:absolute rounded-[16px]"
          />
        </div>
      </div>
    </swiper-slide>
  </swiper>
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay, Mousewheel, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Autoplay, Mousewheel, Pagination],
    };
  },
  data: () => {
    return {
      windowWidth: window.innerWidth,
      first: require("@/assets/carousel/vertical/web-aplikacie-21.png"),
      second: require("@/assets/carousel/vertical/web-aplikacie-22.png"),
      third: require("@/assets/carousel/vertical/web-aplikacie-23.png"),
      slideIterator: 0,
      scrollSwiper: true,
      swiper: null,
    };
  },
  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    },
    onSlideChange() {
      this.slideIterator++;
      if (this.slideIterator == 2) {
        this.slideIterator = 0;
        this.swiper.mousewheel.disable();
      }
    },
  },
};
</script>
<style>
.swiper {
  width: 100%;
  height: 800px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.swiper-pagination-bullet {
  width: 20px;
  height: 22px;
  margin-top: 24px !important;
  margin-bottom: 24px !important;
  border-radius: 0;
  background-size: cover !important;
  background: url("@/assets/carousel/bullet-vertical.png") no-repeat;
}
.swiper-pagination-bullet-active {
  background: url("@/assets/carousel/bullet-active-vertical.png") no-repeat;
}

.swiper-slide img {
  left: 25%;
  top: 18%;
}

@media only screen and (max-width: 500px) {
  .swiper-pagination-vertical.swiper-pagination-bullets,
  .swiper-vertical > .swiper-pagination-bullets {
    right: 5px;
  }
  .swiper-slide img {
    left: 0;
    height: 360px;
    object-fit: cover;
  }
  .swiper-pagination-bullet {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }
}

@media only screen and (min-width: 500px) {
  .swiper-pagination-vertical.swiper-pagination-bullets,
  .swiper-vertical > .swiper-pagination-bullets {
    right: 5%;
  }
  .swiper-slide img {
    left: 25%;
    top: 16%;
    max-width: 400px;
  }
  .swiper-slide {
    font-size: 12px;
  }
  .swiper-pagination-bullet {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }
  .swiper {
    height: 600px;
  }
}

@media only screen and (min-width: 768px) {
  .swiper-pagination-vertical.swiper-pagination-bullets,
  .swiper-vertical > .swiper-pagination-bullets {
    right: 5%;
  }
  .swiper-slide img {
    left: 32%;
    top: 25%;
    max-width: 500px;
  }
  .swiper-pagination-bullet {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }
  .swiper {
    height: 500px;
  }
}

@media only screen and (min-width: 1024px) {
  .swiper-pagination-vertical.swiper-pagination-bullets,
  .swiper-vertical > .swiper-pagination-bullets {
    right: 5%;
  }
  .swiper-slide img {
    left: 30%;
    top: 30%;
    max-width: 600px;
  }
  .swiper-slide {
    font-size: 18px;
  }
  .swiper {
    height: 600px;
  }
}

@media only screen and (min-width: 1280px) {
  .swiper-pagination-vertical.swiper-pagination-bullets,
  .swiper-vertical > .swiper-pagination-bullets {
    right: 5%;
  }
  .swiper-slide img {
    left: 30%;
    top: 26%;
    max-width: 780px;
  }
  .swiper {
    height: 700px;
  }
}

@media only screen and (min-width: 1600px) {
  .swiper-pagination-vertical.swiper-pagination-bullets,
  .swiper-vertical > .swiper-pagination-bullets {
    right: 7%;
  }
  .swiper-slide img {
    left: 25%;
    top: 24%;
    max-width: 900px;
  }
  .swiper {
    height: 800px;
  }
}
@media only screen and (min-width: 1800px) {
  .swiper-pagination-vertical.swiper-pagination-bullets,
  .swiper-vertical > .swiper-pagination-bullets {
    right: 10%;
  }
}

@media only screen and (min-width: 2000px) {
  .swiper-pagination-vertical.swiper-pagination-bullets,
  .swiper-vertical > .swiper-pagination-bullets {
    right: 15%;
  }
}
@media only screen and (min-width: 2300px) {
  .swiper-pagination-vertical.swiper-pagination-bullets,
  .swiper-vertical > .swiper-pagination-bullets {
    right: 20%;
  }
}
</style>
