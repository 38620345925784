<template>
  <div class="w-full h-[4px] bg-[#E5E9EA] relative" ref="div">
    <div
      class="absolute w-[40%] h-full bg-lemonblue top-0 rounded-[2px]"
      :style="style"
    ></div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "ScrollbarComponent",
  props: {
    value: Number,
  },
  data() {
    return {
      style: "left: 0",
    };
  },
  watch: {
    value: function () {
      this.style = `left: ${this?.$refs.div.clientWidth * 0.6 * this?.value}px`;
    },
  },
});
</script>

<style scoped></style>
