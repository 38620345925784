import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-06e05e0a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "max-w-[1440px] md:px-[64px] xl:px-[130px] px-[16px] mx-auto lg:min-w-[1000px] min-h-[400px]" }
const _hoisted_2 = { class: "flex flex-row justify-evenly w-full md:px-[50px]" }
const _hoisted_3 = { class: "flex flex-row items-center w-full relative py-[30px]" }
const _hoisted_4 = { class: "absolute w-full h-[90%] grid lg:grid-rows-3 lg:grid-cols-4 grid-cols-2 grid-rows-4 md:gap-y-[32px] gap-y-[16px] gap-x-[16px] px-[16px] md:pl-[80px] pl-[64px]" }
const _hoisted_5 = { class: "text-[#002132] w-full font-light text-left relative pr-[33px]" }
const _hoisted_6 = { class: "absolute montserrat text-[12px] font-light top-[-5px] left-[-26px] w-[10px] z-10 border-l-solid border-l-[1px] border-l-black pl-[3px]" }
const _hoisted_7 = { class: "w-full h-full grid lg:grid-rows-3 lg:grid-cols-4 grid-cols-2 grid-rows-4 md:gap-y-[32px] gap-y-[16px] gap-x-[16px] px-[16px] md:pl-[80px] pl-[64px]" }
const _hoisted_8 = { class: "text-[#002132] w-full font-light text-left relative pr-[33px]" }
const _hoisted_9 = { class: "absolute montserrat text-[12px] font-light top-[-5px] left-[-26px] w-[10px] z-10 border-l-solid border-l-[1px] border-l-black pl-[3px]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonWithCircle = _resolveComponent("ButtonWithCircle")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.buttons, (button) => {
          return (_openBlock(), _createBlock(_component_ButtonWithCircle, {
            class: "text-[40px]",
            key: button.text,
            text: button.text,
            value: 10,
            onClick: ($event: any) => {
            !button.selected ? (_ctx.click = !_ctx.click) : (_ctx.click = _ctx.click);
            _ctx.setSelected(button.text);
          },
            selected: button.selected
          }, null, 8, ["text", "onClick", "selected"]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          ref: "line",
          class: _normalizeClass([
            _ctx.showAnimation
              ? 'delay-[200ms] translate-x-[-100%]  duration-[500ms]'
              : 'translate-x-[-105%]'
          , "absolute w-full h-full bg-background z-10 border-r-solid border-r-[1px] border-r-[#29adf2]"])
        }, null, 2),
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.areas, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: _normalizeClass(["col-span-1 max-w-[170px] text-left montserrat md:text-[24px] xs:text-[16px] flex flex-col items-center transition ease-in", 
              !_ctx.click
                ? index >= 0 && index < 4
                  ? 'delay-[500ms] translate-x-0 opacity-100 duration-[1200ms]'
                  : index >= 4 && index < 8
                  ? 'delay-[1500ms] translate-x-0 opacity-100 duration-[1200ms]'
                  : 'delay-[2500ms] translate-x-0 opacity-100 duration-[1200ms]'
                : index >= 0 && index < 4
                ? 'delay-[300ms] translate-x-[-100vw] opacity-0 duration-[100ms]'
                : index >= 4 && index < 8
                ? 'delay-[200ms] translate-x-[-100vw] opacity-0 duration-[100ms]'
                : 'delay-[100ms] translate-x-[-100vw] opacity-0 duration-[100ms]'
            ])
            }, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, _toDisplayString(index < 9 ? "0" + (index + 1) : index + 1), 1),
                _createTextVNode(" " + _toDisplayString(item.title), 1)
              ])
            ], 2))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_7, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.roles, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: _normalizeClass(["transition opacity-0 ease-in col-span-1 max-w-[170px] text-left montserrat md:text-[24px] xs:text-[16px] flex flex-col items-center", 
              _ctx.click
                ? index >= 0 && index < 4
                  ? 'delay-[500ms] translate-x-0 opacity-100  duration-[1200ms]'
                  : index >= 4 && index < 8
                  ? 'delay-[1500ms] translate-x-0 opacity-100 duration-[1200ms]'
                  : 'delay-[2500ms] translate-x-0 opacity-100 duration-[1200ms]'
                : index >= 0 && index < 4
                ? 'delay-[300ms] translate-x-[-100vw] opacity-0 duration-[100ms]'
                : index >= 4 && index < 8
                ? 'delay-[200ms] translate-x-[-100vw] opacity-0 duration-[100ms]'
                : 'delay-[100ms] translate-x-[-100vw] opacity-0 duration-[100ms]'
            ])
            }, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, _toDisplayString(index < 9 ? "0" + (index + 1) : index + 1), 1),
                _createTextVNode(" " + _toDisplayString(item.title), 1)
              ])
            ], 2))
          }), 128))
        ])
      ])
    ])
  ]))
}