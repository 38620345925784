<template>
  <div class="max-w-[552px]">
    <div
      class="xs:hidden lg:block text-[#29adf2] montserrat text-left text-[24px] mb-[32px]"
    >
      <span
        class="inline-block h-[1px] w-[70px] bg-[#29adf2] mb-[0.3em] mr-[16px]"
      />DETAIL
    </div>
    <div class="lg:ml-[86px]">
      <div v-if="customer">
        <div class="xs:hidden lg:grid grid grid-cols-2">
          <div
            class="text-[#29adf2] montserrat text-left text-[26px] leading-[32px]"
          >
            Customer:
          </div>
          <div class="montserrat text-left text-[20px] leading-[32px]">
            {{ customer }}
          </div>
        </div>
        <div class="xs:block lg:hidden pb-[8px]">
          <div class="text-[#29adf2] montserrat text-left text-[20px]">
            Customer:
            <span class="montserrat text-left text-[16px] text-[#002132]">{{
              customer
            }}</span>
          </div>
        </div>
      </div>
      <div v-if="technologies">
        <div class="xs:hidden lg:grid grid grid-cols-2">
          <div
            class="text-[#29adf2] montserrat text-left text-[26px] leading-[32px]"
          >
            Technologies:
          </div>
          <div class="montserrat text-left text-[20px] leading-[32px]">
            {{ technologies }}
          </div>
        </div>
        <div class="xs:block lg:hidden pb-[8px]">
          <div class="text-[#29adf2] montserrat text-left text-[20px]">
            Technologies:
            <span class="montserrat text-left text-[16px] text-[#002132]">{{
              technologies
            }}</span>
          </div>
        </div>
      </div>
      <div v-if="url1_text || url2_text">
        <div class="xs:hidden lg:grid grid grid-cols-2">
          <div
            class="text-[#29adf2] montserrat text-left text-[26px] leading-[32px]"
          >
            URL:
          </div>
          <div
            v-if="url1_text"
            class="text-[#29adf2] col-start-2 underline montserrat text-left text-[20px] leading-[32px]"
          >
            <a :href="url1_path" target="_blank">{{ url1_text }}</a>
          </div>
          <div
            v-if="url2_text"
            class="text-[#29adf2] col-start-2 underline montserrat text-left text-[20px] leading-[32px]"
          >
            <a :href="url2_path" target="_blank">{{ url2_text }}</a>
          </div>
        </div>
        <div class="xs:flex lg:hidden">
          <div class="text-[#29adf2] montserrat text-left text-[20px]">
            URL:
          </div>
          <div class="block pl-2 pb-[8px]">
            <div
              v-if="url1_text"
              class="text-[#29adf2] col-start-2 underline montserrat text-left text-[16px]"
            >
              <a :href="url1_path" target="_blank">{{ url1_text }}</a>
            </div>
            <div
              v-if="url2_text"
              class="text-[#29adf2] col-start-2 underline montserrat text-left text-[16px]"
            >
              <a :href="url2_path" target="_blank">{{ url2_text }}</a>
            </div>
          </div>
        </div>
      </div>

      <div v-if="url1_text || url2_text" class="grid grid-cols-2"></div>
      <div v-if="date">
        <div class="xs:hidden lg:grid grid grid-cols-2">
          <div
            class="text-[#29adf2] montserrat text-left text-[26px] leading-[32px]"
          >
            Date:
          </div>
          <div class="montserrat text-left text-[20px] leading-[32px]">
            {{ date }}
          </div>
        </div>
        <div class="xs:block lg:hidden">
          <div class="text-[#29adf2] montserrat text-left text-[20px]">
            Date:
            <span class="montserrat text-left text-[16px] text-[#002132]">{{
              date
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";
import LineAnimation from "@/components/shared/LineAnimation.vue";

@Options({
  props: {
    date: String,
    url1_text: String,
    url1_path: String,
    url2_text: String,
    url2_path: String,
    technologies: String,
    customer: String,
  },
  components: {
    LineAnimation,
  },
})
export default class DetailComponent extends Vue {}
</script>
