<template>
  <router-view :key="$route.path">
    <PageLayout>
      <PageTitle
        :first_line="selected_project.name1"
        :second_line="selected_project.name2"
        :under_text="selected_project.type"
        :small_text="selected_project.under_line"
      >
        <span v-html="selected_project.line_animation" />
      </PageTitle>

      <img
        v-if="selected_project.main_image"
        class="w-[100vw] mt-[30px]"
        :src="getImgUrl(selected_project.main_image)"
        alt="main-picture"
      />

      <DescriptionComponent
        :name="'THE NEED'"
        :blue_text="selected_project.need_blue"
        :black_text="selected_project.need_black"
      />
      <GridLayout
        v-if="selected_project.need_images"
        :items="selected_project.need_images"
        :custom_cards="top_images_cards"
        :show_eye="false"
      />
      <DescriptionComponent
        v-if="selected_project.need_images"
        class=""
        :class="
          selected_project.need_images.length == 3
            ? 'md:mt-[-100px] xs:mt-[20px]'
            : 'md:mt-[50px] xs:mt-[20px]'
        "
        :name="'THE CHALLENGE'"
        :blue_text="selected_project.challenge_blue"
        :black_text="selected_project.challenge_black"
      />
      <div
        class="w-full md:h-[70vh] h-fit flex items-center justify-center md:mb-[160px] md:mt-[100px] mb-[64px] mt-[42px]"
      >
        <ImagesSlider :slides="selected_project.challenge_images" />
      </div>
      <DescriptionComponent
        :name="'OUR SOLUTION'"
        :blue_text="selected_project.solution_blue"
        :black_text="selected_project.solution_black"
      />
      <div
        class="max-w-[1440px] xl:px-[130px] md:px-[64px] px-[16px] mx-auto flex pt-[64px]"
      >
        <div class="grid w-full grid lg:grid-cols-12 xs:grid-cols-1">
          <div class="lg:col-span-7">
            <DetailComponent
              :date="selected_project.date"
              :customer="selected_project.customer"
              :url2_text="selected_project.url2_text"
              :url2_path="selected_project.url2_path"
              :url1_text="selected_project.url1_text"
              :url1_path="selected_project.url1_path"
              :technologies="selected_project.technologies"
            />
          </div>
          <div class="lg:col-span-5"></div>
        </div>
      </div>

      <GridLayout
        v-if="selected_project.solution_images"
        :items="selected_project.solution_images"
        :custom_cards="bottom_images_cards"
        :show_eye="false"
      />
      <div
        class="max-w-[1440px] xl:px-[130px] md:px-[64px] px-[16px] mx-auto text-[#29adf2] montserrat text-left lg:text-[72px] md:text-[70px] xs:text-[40px] font-black mb-[70px] mt-[100px]"
      >
        Other tasty things
        {{ $route.path }}
      </div>

      <Slider class="mb-[50px]" :slides="slides" />
    </PageLayout>
  </router-view>
</template>
<script lang="ts">
import { useHead } from "@vueuse/head";
import { defineComponent } from "vue";
import getSiteMeta from "../utils/getSiteMeta";
import PageLayout from "@/components/PageLayout.vue";
import DetailComponent from "@/components/project_components/DetailComponent.vue";
import DescriptionComponent from "@/components/project_components/DescriptionComponent.vue";
import PageTitle from "@/components/project_components/PageTitle.vue";
import GridLayout from "@/components/GridLayout.vue";
import { useRoute } from "vue-router";
import Slider from "@/components/shared/Slider.vue";
import ImagesSlider from "@/components/project_components/ImagesSlider.vue";

interface IMetaObject {
  path: string;
  title: string;
  description: string;
  url: string;
}

export default defineComponent({
  name: "AboutView",
  components: {
    PageLayout,
    DetailComponent,
    DescriptionComponent,
    PageTitle,
    GridLayout,
    Slider,
    ImagesSlider,
  },
  mounted() {
    const route = useRoute();
    this.project_slug = route.params.id.toString();
    this.getProject();
  },
  updated() {
    const route = useRoute();
    this.project_slug = route.params.id.toString();
    this.setSEO();
    this.getProject();
  },
  methods: {
    getProject() {
      const filtered = this.projects.filter(
        (item) => this.project_slug === item.path
      );
      if (filtered.length > 0) {
        this.selected_project = filtered[0];
      }
    },
    getImgUrl: function (img) {
      return require("@/assets/" + img);
    },
    setSEO() {
      const route = useRoute();
      const metaObject = this.meta.find(
        (e: IMetaObject) => e.path === route.path
      );

      useHead({
        title: metaObject?.title,
        meta: getSiteMeta(metaObject),
      });
    },
  },
  data: () => {
    return {
      projects: require("@/assets/detail projektu/projects.json"),
      slides: [
        {
          content: "Slide content.",
          path: require("@/assets/carousel/Ucko.png"),
          pathMobile: require("@/assets/carousel/home-mobile/ucko.png"),
          app: "Učko",
          subtitle: "Custom development",
          link: "ucko",
        },
        {
          content: "Slide content.",
          path: require("@/assets/carousel/EFFY.png"),
          pathMobile: require("@/assets/carousel/home-mobile/effy.png"),
          app: "Effy",
          subtitle: "Mobile app",
          link: "effy",
        },
        {
          content: "Slide content.",
          path: require("@/assets/carousel/ESAC.png"),
          pathMobile: require("@/assets/carousel/home-mobile/esac.png"),
          app: "Esac",
          subtitle: "Mobile app",
          link: "esac",
        },
        {
          content: "Slide content.",
          path: require("@/assets/carousel/Tessa.png"),
          pathMobile: require("@/assets/carousel/home-mobile/tessa.png"),
          app: "Tessa",
          subtitle: "Web app",
          link: "tessa",
        },
        {
          content: "Slide content.",
          path: require("@/assets/carousel/Way2Eco.png"),
          pathMobile: require("@/assets/carousel/home-mobile/Way2Go.png"),
          app: "Way2Eco",
          subtitle: "Web app",
          link: "way2eco",
        },
        {
          content: "Slide content.",
          path: require("@/assets/carousel/6.png"),
          pathMobile: require("@/assets/carousel/home-mobile/6m.png"),
          last: "true",
          link: "",
        },
      ],

      project_slug: "",
      selected_project: {},
      top_images_cards: [
        { top: 78, left: 156, width: 456, height: 634 },
        { top: 528, left: 827, width: 648, height: 428 },
        { top: 872, left: -58, width: 861, height: 488 },
      ],

      bottom_images_cards: [
        { top: -100, left: 828, width: 456, height: 634 },
        { top: 260, left: 61, width: 648, height: 428 },
        { top: 650, left: 732, width: 861, height: 488 },
        { top: 768, left: 157, width: 456, height: 634 },
        { top: 1218, left: 828, width: 648, height: 428 },
        { top: 1562, left: -57, width: 861, height: 488 },
      ],
      bottom_images: [],
      meta: [
        {
          path: "/work/esac",
          title: "Esac | Blue Lemons",
          description:
            "Esac is system with data visualization displaying in dashboards.",
          url: "http://bluelemons.sk/work/esac",
        },
        {
          path: "/work/tessa",
          title: "Tessa | Blue Lemons",
          description:
            "Tessa is cloud platform for monitoring and recording employee attendance.",
          url: "http://bluelemons.sk/work/tessa",
        },
        {
          path: "/work/booking-slovakia",
          title: "Booking Slovakia | Blue Lemons",
          description:
            "Booking Slovakia is web application for accomodation reservation system.",
          url: "http://bluelemons.sk/work/booking-slovakia",
        },
        {
          path: "/work/effy",
          title: "Effy | Blue Lemons",
          description:
            "Effy is application for 6 - 14 years old kids increasing financial literacy.",
          url: "http://bluelemons.sk/work/effy",
        },
        {
          path: "/work/go2help",
          title: " Go2Help | Blue Lemons",
          description:
            "Go2Help is mobile app that combines purchase of the tickets and donation.",
          url: "http://bluelemons.sk/work/go2help",
        },
        {
          path: "/work/najdite-sa",
          title: "Nájdite sa | Blue Lemons",
          description:
            "Nájdite sa is an application that helps shelters facilitate the animal adoption process.",
          url: "http://bluelemons.sk/work/najdite-sa",
        },
        {
          path: "/work/k13",
          title: "K13 | Blue Lemons",
          description:
            "K13 is a platform for the presentation of activities of cultural centers.",
          url: "http://bluelemons.sk/work/k13",
        },
        {
          path: "/work/viarest",
          title: "Via Rest | Blue Lemons",
          description:
            "ViaRest is an application that helps shelters facilitate the animal adoption process.",
          url: "http://bluelemons.sk/work/viarest",
        },
        {
          path: "/work/divadlo-na-perone",
          title: "Divadlo na peróne | Blue Lemons",
          description:
            "Divadlo na pérone is a web application for the theater and its visitors.",
          url: "http://bluelemons.sk/work/divadlo-na-perone",
        },
        {
          path: "/work/way2eco",
          title: "Way2eco | Blue Lemons",
          description:
            "Way2Eco is an application that helps to reduce personal carbon footprint.",
          url: "http://bluelemons.sk/work/way2eco",
        },
        {
          path: "/work/visit-kosice",
          title: "Visit Košice | Blue Lemons",
          description:
            "Visit Košice is a web app used for tracking and supporting the culture in Košice.",
          url: "http://bluelemons.sk/work/visit-kosice",
        },
        {
          path: "/work/crypton-digital",
          title: "Crypton Digital | Blue Lemons",
          description:
            "Crypto Digital is a crypto investment platform for crypto enthusiasts",
          url: "http://bluelemons.sk/work/crypton-digital",
        },
        {
          path: "/work/biela-noc",
          title: "Biela Noc | Blue Lemons",
          description:
            "Biela noc is a mobile app digitazing all information about art festival.",
          url: "http://bluelemons.sk/work/biela-noc",
        },
        {
          path: "/work/swift-shift",
          title: "Swift Shift | Blue Lemons",
          description:
            "Project is a solution for connecting new nurses with potential patients.",
          url: "http://bluelemons.sk/work/swift-shift",
        },
        {
          path: "/work/stredna",
          title: "Stredná | Blue Lemons",
          description:
            "Stredná is a useful application that helps young students.",
          url: "http://bluelemons.sk/work/stredna",
        },
        {
          path: "/work/ucko",
          title: "Učko | Blue Lemons",
          description: "Učko is a useful application that helps teachers.",
          url: "http://bluelemons.sk/work/ucko",
        },
      ],
    };
  },
  beforeMount() {
    this.setSEO();
  },
});
</script>
