<template>
  <carousel
    :autoplay="5000"
    :items-to-show="itemsToShow"
    :wrap-around="true"
    class="w-full slider-tech"
    @mouseover="hoverArrows"
    @mouseleave="unhoverArrows"
  >
    <slide v-for="(slide, key, index) in slides" :key="index">
      <div class="carousel__item">
        <img
          @mouseover="slide.hover = true"
          @mouseleave="slide.hover = false"
          :src="slide.hover ? slide.path2 : slide.path"
          :cover="true"
          class="slide-image"
        />
      </div>
    </slide>

    <template #addons>
      <navigation />
    </template>
  </carousel>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";

export default {
  name: "Slider-component",
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  mounted() {
    if (window.innerWidth < 600) {
      this.itemsToShow = 3;
    }
  },
  data: () => {
    return {
      hoverImage: false,
      bullet: require("@/assets/carousel/bullet.png"),
      bulletActive: require("@/assets/carousel/bullet-active.png"),
      viewMoreDefault: require("@/assets/carousel/view-more-default.png"),
      viewMoreHover: require("@/assets/carousel/view-more-hover.png"),
      eye: require("@/assets/animations/eyeB.mp4"),
      itemsToShow: 5,
      slides: [
        {
          content: "Slide content.",
          path: require("@/assets/carousel/technologies/14.png"),
          path2: require("@/assets/carousel/technologies/14C.png"),
          hover: false,
        },
        {
          content: "Slide content.",
          path: require("@/assets/carousel/technologies/15.png"),
          path2: require("@/assets/carousel/technologies/15C.png"),
          hover: false,
        },
        {
          content: "Slide content.",
          path: require("@/assets/carousel/technologies/16.png"),
          path2: require("@/assets/carousel/technologies/16C.png"),
          hover: false,
        },
        {
          content: "Slide content.",
          path: require("@/assets/carousel/technologies/17.png"),
          path2: require("@/assets/carousel/technologies/17C.png"),
          hover: false,
        },
        {
          content: "Slide content.",
          path: require("@/assets/carousel/technologies/18.png"),
          path2: require("@/assets/carousel/technologies/18C.png"),
          hover: false,
        },
        {
          content: "Slide content.",
          path: require("@/assets/carousel/technologies/19.png"),
          path2: require("@/assets/carousel/technologies/19C.png"),
          hover: false,
        },
        {
          content: "Slide content.",
          path: require("@/assets/carousel/technologies/110.png"),
          path2: require("@/assets/carousel/technologies/110C.png"),
          hover: false,
        },
        {
          content: "Slide content.",
          path: require("@/assets/carousel/technologies/111.png"),
          path2: require("@/assets/carousel/technologies/111C.png"),
          hover: false,
        },
        {
          content: "Slide content.",
          path: require("@/assets/carousel/technologies/112.png"),
          path2: require("@/assets/carousel/technologies/112C.png"),
          hover: false,
        },
        {
          content: "Slide content.",
          path: require("@/assets/carousel/technologies/113.png"),
          path2: require("@/assets/carousel/technologies/113C.png"),
          hover: false,
        },
        {
          content: "Slide content.",
          path: require("@/assets/carousel/technologies/114.png"),
          path2: require("@/assets/carousel/technologies/114C.png"),
          hover: false,
        },
        {
          content: "Slide content.",
          path: require("@/assets/carousel/technologies/115.png"),
          path2: require("@/assets/carousel/technologies/115C.png"),
          hover: false,
        },
        {
          content: "Slide content.",
          path: require("@/assets/carousel/technologies/116.png"),
          path2: require("@/assets/carousel/technologies/116C.png"),
          hover: false,
        },
        {
          content: "Slide content.",
          path: require("@/assets/carousel/technologies/117.png"),
          path2: require("@/assets/carousel/technologies/117C.png"),
          hover: false,
        },
        {
          content: "Slide content.",
          path: require("@/assets/carousel/technologies/118.png"),
          path2: require("@/assets/carousel/technologies/118C.png"),
          hover: false,
        },
      ],
    };
  },
  methods: {
    hoverArrows() {
      const prev = document.getElementsByClassName("carousel__prev");
      prev[0].classList.add("arrow-hover-prev");
      const next = document.getElementsByClassName("carousel__next");
      next[0].classList.add("arrow-hover-next");
    },
    unhoverArrows() {
      const prev = document.getElementsByClassName("carousel__prev");
      prev[0].classList.remove("arrow-hover-prev");
      const next = document.getElementsByClassName("carousel__next");
      next[0].classList.remove("arrow-hover-next");
    },
  },
};
</script>

<style>
.slider-tech .carousel__track {
  height: 120%;
}
.slider-tech .carousel__item {
  max-height: 1280px;
  width: 100%;
  color: var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-tech .carousel__item img {
  min-height: initial;
  height: 96px;
  width: 96px;
  opacity: 0.7;
}

.slider-tech .carousel__slide {
  padding: 10px;
}

.slider-tech .carousel__icon {
  display: none;
}

.slider-tech .carousel__prev {
  right: auto;
  left: 0em;
  background-image: url("@/assets/carousel/left-default.png") !important;
  width: 64px;
  height: 64px;
  background-size: cover;
  background-color: transparent;
}

.slider-tech .carousel__next {
  right: 0em;
  left: auto;
  background-image: url("@/assets/carousel/right-default.png") !important;
  width: 64px;
  height: 64px;
  background-size: cover;
  background-color: transparent;
}

.slider-tech .arrow-hover-prev {
  background-image: url("@/assets/carousel/left-hover.png") !important;
}

.slider-tech .arrow-hover-next {
  background-image: url("@/assets/carousel/right-hover.png") !important;
}

.slider-tech .carousel__prev--in-active {
  display: none;
}
@media only screen and (max-width: 600px) {
  .slider-tech .carousel__prev {
    display: none;
  }
  .slider-tech .carousel__next {
    display: none;
  }
  .slider-tech .carousel__item img {
    height: 48px;
    width: 48px;
  }
}

@media only screen and (min-width: 600px) {
  .slider-tech .carousel__prev {
    right: auto;
    left: -2em;
    width: 64px;
    height: 64px;
  }
  .slider-tech .carousel__next {
    right: -2em;
    left: auto;
    width: 64px;
    height: 64px;
  }
  .slider-tech .carousel__item img {
    height: 48px;
    width: 48px;
  }
}
@media only screen and (min-width: 1440px) {
  .slider-tech .carousel__prev {
    right: auto;
    left: 0em;
    width: 96px;
    height: 96px;
  }
  .slider-tech .carousel__next {
    right: 0em;
    left: auto;
    width: 96px;
    height: 96px;
  }
  .slider-tech .carousel__item img {
    height: 96px;
    width: 96px;
  }
}
</style>
