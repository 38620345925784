<template>
  <PageLayout show_drop>
    <PageTitle :first_line="'SELECTED'" :second_line="'CROP'">
      <span class="font-bold text-[#29adf2]">of 70+ professionals </span> in two
      cities who work with
      <span class="font-bold text-[#29adf2]">best technologies.</span>
    </PageTitle>
    <img
      src="@/assets/images/about_top_image.png"
      class="w-full h-[50vw] md:h-auto object-cover"
      alt="about us"
    />

    <div
      class="w-full flex justify-content items-center max-width-limit mt-[56px] md:mt-[120px]"
    >
      <div class="md:flex items-center mx-auto gap-[7%]">
        <div class="w-full md:w-[40%] mb-[56px] md:mb-0">
          <Number
            :value="20"
            plus
            big
            autoplay
            :offset="200"
            class="leading-[108px]"
          />
          <div class="overflow-hidden">
            <h4
              class="text-center md:text-left text-[42px] md:text-[34px] leading-[50px] text-lemonblue md:ml-[30px] translate-x-[-100%] transition ease-in opacity-0 duration-[1000ms] delay-[400ms]"
              :class="showImagination ? 'translate-x-0 opacity-100' : ''"
            >
              clients
            </h4>
          </div>
        </div>
        <div class="w-full md:w-[60%]">
          <LineAnimation
            :main_title="false"
            :line_color="'#29adf2'"
            class="overflow-hidden w-full"
            :delay="10"
          >
            <span class="text-[#29adf2]"
              >Creating innovative product, <br class="md:block hidden" />
              beyond your IMAGINATION.</span
            >
          </LineAnimation>
          <div class="overflow-hidden">
            <p
              ref="imagination"
              class="text-left mt-[32px] md:mt-[55px] md:pr-[70px] text-[16px] md:text-[20px] leading-[24px] md:leading-[28px] text-animation translate-x-[-100%] opacity-0 transition ease-in duration-[1500ms] delay-[2000ms]"
              :class="showImagination ? 'translate-x-0 opacity-100' : ''"
            >
              We understand the importance of modern trends and creative ideas
              in innovative solutions. We stay up to date on what's new and make
              sure you're using the latest and greatest software patterns.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="xs:mt-[50px] xs:mb-[100px] sm:mt-[150px] sm:mb-[150px]">
      <Slider :slides="slides" :hover="false" />
    </div>

    <div
      class="w-full flex justify-content items-center max-width-limit mt-[56px] md:mt-[120px]"
    >
      <div class="md:flex items-center mx-auto gap-[7%] z-10">
        <div class="w-full md:w-[40%] mb-[56px] md:mb-0">
          <Number
            :value="70"
            plus
            big
            autoplay
            :offset="200"
            class="leading-[108px]"
          />
          <div class="overflow-hidden">
            <h4
              class="text-center md:text-left text-[42px] md:text-[34px] text-lemonblue leading-[50px] md:ml-[30px] translate-x-[-100%] transition ease-in duration-[1000ms] delay-[400ms] opacity-0"
              :class="showImagination2 ? 'translate-x-0 opacity-100' : ''"
            >
              professionals
            </h4>
          </div>
        </div>
        <div class="w-full md:w-[60%]">
          <LineAnimation
            :main_title="false"
            :line_color="'#29adf2'"
            class="overflow-hidden w-full"
            :delay="10"
          >
            <span class="text-[#29adf2]">
              Blue Lemons specializes in designing enterprise applications that
              drive profit, ability and growth.
            </span>
          </LineAnimation>
          <div class="overflow-hidden">
            <p
              ref="imagination2"
              class="text-left mt-[32px] md:mt-[55px] md:pr-[70px] text-[16px] md:text-[20px] leading-[24px] md:leading-[28px] text-animation translate-x-[-100%] transition ease-in duration-[1500ms] delay-[2000ms] opacity-0"
              :class="showImagination2 ? 'translate-x-0 opacity-100' : ''"
            >
              Our user-first approach towards development allows us to make
              complex processes simple and user-friendly. Our focus on design
              and experience is what sets us apart from other development
              companies, and ulti- mately what makes our applications more
              effective.
            </p>
          </div>
        </div>
      </div>
    </div>

    <img
      src="@/assets/images/about_bottom_image.png"
      class="w-full object-cover md:mt-[-120px] z-[-1]"
      alt="about us"
    />

    <div
      class="bg-lemonblue w-full h-auto lg:h-[320px] flex justify-center items-center"
    >
      <div
        class="my-[32px] lg:m-0 flex flex-col lg:flex-row justify-center items-center lg:items-start text-white max-width-limit"
      >
        <h3
          class="font-bold lg:w-[47.5%] text-left pr-[30px] lg:pr-0 text-[24px] leading-[26px] md:text-[48px] md:leading-[59px]"
        >
          We work in three <br class="hidden lg:block" />beautiful cities in<br
            class="hidden md:block"
          />
          the middle of Europe.
        </h3>
        <div class="flex lg:w-[47.5%] mt-[40px] lg:mt-0">
          <LineAnimation
            :main_title="false"
            :line_color="'#fff'"
            class="line-inline mt-[-5px] md:mt-[-10px] md:mr-[5px]"
          >
          </LineAnimation>
          <h5
            class="text-left text-[16px] leading-[21px] md:text-[24px] md:leading-[33px]"
          >
            Thanks to modern spaces and a family atmosphere, we can create
            products that people love to use.
          </h5>
        </div>
      </div>
    </div>
  </PageLayout>
</template>
<script lang="ts">
import { useHead } from "@vueuse/head";
import { defineComponent } from "vue";
import getSiteMeta from "../utils/getSiteMeta";
import PageLayout from "@/components/PageLayout.vue";
import PageTitle from "@/components/shared/PageTitle.vue";
import Number from "@/components/Number.vue";
import LineAnimation from "@/components/shared/LineAnimation.vue";
import Slider from "@/components/shared/Slider.vue";

export default defineComponent({
  name: "AboutView",
  setup() {
    const meta = {
      title: "About | Blue Lemons",
      description:
        "Selected crop of 70 + professionals in three cities who work with best technologies",
      url: "http://bluelemons.sk/about",
    };

    useHead({
      title: meta.title,
      meta: getSiteMeta(meta),
    });
  },
  data: () => {
    return {
      showImagination: false,
      showImagination2: false,
      slides: [
        {
          content: "Slide content.",
          path: require("@/assets/carousel/about/2.png"),
          pathMobile: require("@/assets/carousel/about/21.png"),
          link: "",
        },
        {
          content: "Slide content.",
          path: require("@/assets/carousel/about/3.png"),
          pathMobile: require("@/assets/carousel/about/31.png"),
          link: "",
        },
        {
          content: "Slide content.",
          path: require("@/assets/carousel/about/4.png"),
          pathMobile: require("@/assets/carousel/about/41.png"),
          link: "",
        },
        {
          content: "Slide content.",
          path: require("@/assets/carousel/about/5.png"),
          pathMobile: require("@/assets/carousel/about/51.png"),
          link: "",
        },
        {
          content: "Slide content.",
          path: require("@/assets/carousel/about/6.png"),
          pathMobile: require("@/assets/carousel/about/61.png"),
          link: "",
        },
        {
          content: "Slide content.",
          path: require("@/assets/carousel/about/7.png"),
          pathMobile: require("@/assets/carousel/about/71.png"),
          link: "",
        },
      ],
    };
  },
  components: {
    PageLayout,
    PageTitle,
    Number,
    LineAnimation,
    Slider,
  },
  methods: {
    checkScroll() {
      if (
        window.scrollY + window.innerHeight >
        (this.$refs.imagination as HTMLDivElement).offsetTop + 100
      )
        this.showImagination = true;

      if (
        window.scrollY + window.innerHeight >
        (this.$refs.imagination2 as HTMLDivElement).offsetTop + 100
      )
        this.showImagination2 = true;
    },
  },
  computed: {
    getHeight() {
      return window.innerHeight;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.checkScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.checkScroll);
  },
});
</script>

<style scoped>
.line-inline p {
  display: flex;
}
</style>
