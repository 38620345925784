<template>
  <div
    class="fixed w-[100vw] h-[100vh] z-[100] top-0 left-0 md:hidden translate-y-[150px] transition ease-in duration-[300ms] pointer-events-none"
    :class="{ 'translate-y-0': showButton }"
  >
    <svg
      width="96"
      height="104"
      viewBox="0 0 96 104"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="right-[5px] bottom-[30px] absolute pointer-events-auto"
      @click="toTop"
    >
      <g filter="url(#filter0_dd_2197_32639)">
        <path
          d="M77.8111 59.6581C77.0986 60.8766 77.252 63.8309 77.297 64.5236C77.297 64.7572 77.2069 67.336 75.1868 68.6963C73.1667 70.0566 71.4984 69.7395 68.7388 69.2137L67.9903 69.0718C66.6833 68.9599 65.3651 69.0587 64.0945 69.3639C59.9372 70.0566 52.9661 71.225 43.4791 66.2511C30.3126 59.3494 26.6062 50.5699 24.5681 42.1243C22.8636 35.0891 25.4158 26.8521 26.7054 24.9326C27.6897 23.3872 28.2125 21.6274 28.2204 19.8335C28.2241 19.026 28.386 18.2258 28.6984 17.4717C28.7431 17.3648 28.7974 17.2615 28.8607 17.163C29.4104 16.3384 30.1417 15.6303 31.007 15.0849C31.6504 14.6229 32.3859 14.2826 33.1714 14.0835H33.2886C33.5853 14.027 33.8875 13.999 34.1904 14C35.2184 14.0537 36.2242 14.2977 37.1483 14.7177L37.6173 14.893C38.8076 15.2324 40.0736 15.2756 41.2877 15.0182C43.6255 14.7038 45.9941 14.6338 48.3489 14.8095C54.4812 15.2352 61.9843 18.9155 66.2138 22.3205C72.3622 27.1718 76.7821 33.6299 78.8933 40.8474C81.6348 49.969 78.5055 58.4731 77.8111 59.6581Z"
          fill="#29ADF2"
        />
      </g>
      <rect
        x="51"
        y="54"
        width="21.7143"
        height="2"
        transform="rotate(-90 51 54)"
        fill="white"
      />
      <path d="M52 30L57.1962 35.1429L46.8038 35.1429L52 30Z" fill="white" />
      <defs>
        <filter
          id="filter0_dd_2197_32639"
          x="0"
          y="0"
          width="104"
          height="104"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="10" />
          <feGaussianBlur stdDeviation="12" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.032424 0 0 0 0 0.0034375 0 0 0 0 0.1375 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2197_32639"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0313726 0 0 0 0 0.00392157 0 0 0 0 0.137255 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_2197_32639"
            result="effect2_dropShadow_2197_32639"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_2197_32639"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  </div>
</template>

<script>
export default {
  name: "FloatingButton",
  data() {
    return {
      showButton: false,
    };
  },
  methods: {
    toTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    checkScroll() {
      this.showButton = window.scrollY > window.innerHeight;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.checkScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.checkScroll);
  },
};
</script>

<style scoped></style>
