<template>
  <PageLayout :show_drop="false">
    <div class="max-width-limit">
      <h1
        class="font-[900] text-[36px] text-[#29ADF2] leading-[45px] md:text-[90px] md:leading-[100px] montserrat text-left mt-[30px] mb-[20px] md:mt-[72px] md:mb-[50px]"
      >
        CONTACT FORM
      </h1>
      <div class="md:max-w-[512px] w-full">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-[32px]">
          <!-- NameSurnameInut -->
          <div>
            <label class="input">
              <input
                class="input__field montserrat"
                v-model="v$.name.$model"
                :class="
                  v$.name.$errors.length > 0
                    ? 'input__field-error'
                    : v$.name.$model.trim()
                    ? 'input__field-active'
                    : ''
                "
              />
              <span
                class="input__label montserrat"
                :class="v$.name.$model.trim() ? 'not_blank' : ''"
                :style="v$.name.$errors.length > 0 ? { color: '#dc362e' } : {}"
                >Name and surname *</span
              >
            </label>
            <div
              class="input-errors"
              v-for="error of v$.name.$errors"
              :key="error.$uid"
            >
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
          <!-- CompanyInput  -->
          <div>
            <label class="input">
              <input
                class="input__field montserrat"
                v-model="company"
                :class="company.trim() ? 'input__field-active' : ''"
              />
              <span
                class="input__label montserrat"
                :class="company.trim() ? 'not_blank' : ''"
                >Company</span
              >
            </label>
          </div>
          <!-- EmailInput -->
          <div>
            <label class="input">
              <input
                class="input__field montserrat"
                v-model="v$.email.$model"
                :class="
                  v$.email.$errors.length > 0
                    ? 'input__field-error'
                    : v$.email.$model.trim()
                    ? 'input__field-active'
                    : ''
                "
              />
              <span
                class="input__label montserrat"
                :class="v$.email.$model.trim() ? 'not_blank' : ''"
                :style="v$.email.$errors.length > 0 ? { color: '#dc362e' } : {}"
                >E-mail *</span
              >
            </label>
            <div
              class="input-errors"
              v-for="error of v$.email.$errors"
              :key="error.$uid"
            >
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
          <!-- NumberInput -->
          <div>
            <label class="input">
              <input
                class="input__field montserrat"
                v-model="v$.number.$model"
                :class="
                  v$.number.$errors.length > 0
                    ? 'input__field-error'
                    : v$.number.$model.trim()
                    ? 'input__field-active'
                    : ''
                "
              />
              <span
                class="input__label montserrat"
                :class="v$.number.$model.trim() ? 'not_blank' : ''"
                :style="
                  v$.number.$errors.length > 0 ? { color: '#dc362e' } : {}
                "
                >Phone number *</span
              >
            </label>
            <div
              class="input-errors"
              v-for="error of v$.number.$errors"
              :key="error.$uid"
            >
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
          <!-- EmployeesInput -->
          <div>
            <label class="input">
              <input
                class="input__field montserrat"
                v-model="employees"
                :class="employees.trim() ? 'input__field-active' : ''"
              />
              <span
                class="input__label montserrat"
                :class="employees.trim() ? 'not_blank' : ''"
                >No. of employees</span
              >
            </label>
          </div>
          <!-- ServiceSelector -->
          <div
            ref="selector"
            class="w-full md:w-[240px] min-h-[40px] relative selection cursor-pointer"
            :class="service.length > 0 ? 'selection_active' : ''"
            @focus="
              {
              }
            "
            v-on:click="
              serviceOptions.length > 0 ? (selectionServiceOpen = true) : {}
            "
            @focusout="selectionServiceOpen = false"
            tabindex="0"
          >
            <div
              class="flex flex-col items-start justify-start gap-[4px] mx-[16px] my-[10px]"
            >
              <div
                class="font-[500] max-w-[90%] text-left leading-[19px] text-[16px] text-white relative bg-[#29ADF2] rounded-[16px] pl-[12px] py-[4px] flex items-center cursor-pointer"
                v-for="(item, index) in service"
                :key="index"
                v-on:click="
                  previousLength = service.length;
                  serviceOptions.push(item);
                  service = service.filter((x) => x != item);
                "
              >
                {{ item }}
                <div class="p-[5px] pr-[12px] cursor-pointer">
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.99998 8.99998L1 1"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.00002 1L1 9.00002"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              class="absolute top-[16px] right-[18px] ease-in duration-200"
              :class="!selectionServiceOpen ? 'rotate-180 ' : 'rotate-0'"
            >
              <svg
                width="10"
                height="6"
                viewBox="0 0 10 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.292893 4.29289C-0.0976311 4.68342 -0.0976311 5.31658 0.292893 5.70711C0.683418 6.09763 1.31658 6.09763 1.70711 5.70711L0.292893 4.29289ZM5 1L5.70711 0.292893C5.31658 -0.0976311 4.68342 -0.0976311 4.29289 0.292893L5 1ZM8.29289 5.70711C8.68342 6.09763 9.31658 6.09763 9.70711 5.70711C10.0976 5.31658 10.0976 4.68342 9.70711 4.29289L8.29289 5.70711ZM1.70711 5.70711L5.70711 1.70711L4.29289 0.292893L0.292893 4.29289L1.70711 5.70711ZM4.29289 1.70711L8.29289 5.70711L9.70711 4.29289L5.70711 0.292893L4.29289 1.70711Z"
                  :fill="selectionServiceOpen ? '#14181F' : '#667a84'"
                />
              </svg>
            </div>
            <span
              class="selection__label montserrat"
              :class="
                selectionServiceOpen || service.length > 0
                  ? 'selection_open'
                  : ''
              "
              >Service</span
            >
            <div
              class="w-full absolute top-[30px] left-0 bg-[transparent] overflow-hidden ease-in duration-300 z-[1] pt-[10px]"
              :class="
                selectionServiceOpen
                  ? 'max-h-[280px] selection__options'
                  : 'max-h-[0px]'
              "
              :style="
                service.length > 0
                  ? { top: 'calc(100% - 5px)' }
                  : { top: '30px' }
              "
            >
              <p
                v-for="(item, index) in serviceOptions"
                :key="index"
                v-on:click="
                  service.push(item);
                  previousLength = service.length;
                  serviceOptions = serviceOptions.filter((x) => x != item);
                  serviceOptions.length == 0
                    ? (selectionServiceOpen = false)
                    : {};
                "
                class="w-full text-left text-[14px] leading-[17px] font-[500] cursor-pointer hover:bg-[#29ADF2] bg-[white] hover:text-white text-[#112443] flex items-center px-[16px] pt-[12px] pb-[18px]"
              >
                {{ item }}
              </p>
            </div>
          </div>
          <!-- BudgetSelector -->
          <div
            class="w-full md:w-[240px] h-[40px] relative selection cursor-pointer"
            :class="budget > -1 ? 'selection_active' : ''"
            @focus="
              {
              }
            "
            v-on:click="selectionBudgetOpen = true"
            @focusout="selectionBudgetOpen = false"
            tabindex="0"
          >
            <p v-if="budget > -1" class="budget__field">
              {{ budgetOptions[budget] }}
            </p>
            <div
              class="absolute top-[16px] right-[18px] ease-in duration-200"
              :class="!selectionBudgetOpen ? 'rotate-180 ' : 'rotate-0'"
            >
              <svg
                width="10"
                height="6"
                viewBox="0 0 10 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.292893 4.29289C-0.0976311 4.68342 -0.0976311 5.31658 0.292893 5.70711C0.683418 6.09763 1.31658 6.09763 1.70711 5.70711L0.292893 4.29289ZM5 1L5.70711 0.292893C5.31658 -0.0976311 4.68342 -0.0976311 4.29289 0.292893L5 1ZM8.29289 5.70711C8.68342 6.09763 9.31658 6.09763 9.70711 5.70711C10.0976 5.31658 10.0976 4.68342 9.70711 4.29289L8.29289 5.70711ZM1.70711 5.70711L5.70711 1.70711L4.29289 0.292893L0.292893 4.29289L1.70711 5.70711ZM4.29289 1.70711L8.29289 5.70711L9.70711 4.29289L5.70711 0.292893L4.29289 1.70711Z"
                  :fill="selectionBudgetOpen ? '#14181F' : '#667a84'"
                />
              </svg>
            </div>
            <span
              class="selection__label montserrat"
              :class="
                selectionBudgetOpen || budget > -1 ? 'selection_open' : ''
              "
              >Expected budget</span
            >
            <div
              class="w-full absolute top-[30px] left-0 bg-[transparent] overflow-hidden ease-in duration-300 z-[1] pt-[10px]"
              :class="
                selectionBudgetOpen
                  ? 'max-h-[280px] selection__options'
                  : 'max-h-[0px]'
              "
            >
              <p
                v-for="(item, index) in budgetOptions"
                :key="index"
                v-on:mouseover="
                  index == budget ? (color = 'white') : (color = '#14181F')
                "
                v-on:mouseleave="color = '#14181F'"
                v-on:click="
                  budget = index;
                  color = 'white';
                "
                class="w-full text-left text-[14px] leading-[17px] font-[500] cursor-pointer hover:bg-[#29ADF2] bg-[white] hover:text-white text-[#112443] flex items-center px-[16px] pt-[12px] pb-[18px] flex justify-between"
              >
                {{ item }}
                <span v-if="index == budget">
                  <svg
                    width="15"
                    height="12"
                    viewBox="0 0 15 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 6.00045L5.24264 10.2431L13.7279 1.75781"
                      :stroke="color"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </p>
            </div>
          </div>
          <!-- HearAboutUsSelector -->
          <div
            ref="selector"
            class="w-full md:w-[240px] min-h-[40px] relative selection cursor-pointer"
            :class="hearAboutUs.length > 0 ? 'selection_active' : ''"
            @focus="
              {
              }
            "
            v-on:click="
              hearAboutUsOptions.length > 0
                ? (selectionHearAboutUsOpen = true)
                : {}
            "
            @focusout="selectionHearAboutUsOpen = false"
            tabindex="0"
          >
            <div
              class="flex flex-col items-start justify-start gap-[4px] mx-[16px] my-[10px]"
            >
              <div
                class="font-[500] max-w-[90%] leading-[19px] text-[16px] text-white text-left relative bg-[#29ADF2] rounded-[16px] pl-[12px] py-[4px] flex items-center cursor-pointer"
                v-for="(item, index) in hearAboutUs"
                :key="index"
                v-on:click="
                  previousLength = hearAboutUs.length;
                  hearAboutUsOptions.push(item);
                  hearAboutUs = hearAboutUs.filter((x) => x != item);
                "
              >
                {{ item }}
                <div class="p-[5px] pr-[12px] cursor-pointer">
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.99998 8.99998L1 1"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.00002 1L1 9.00002"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div
              class="absolute top-[16px] right-[18px] ease-in duration-200"
              :class="!selectionHearAboutUsOpen ? 'rotate-180 ' : 'rotate-0'"
            >
              <svg
                width="10"
                height="6"
                viewBox="0 0 10 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.292893 4.29289C-0.0976311 4.68342 -0.0976311 5.31658 0.292893 5.70711C0.683418 6.09763 1.31658 6.09763 1.70711 5.70711L0.292893 4.29289ZM5 1L5.70711 0.292893C5.31658 -0.0976311 4.68342 -0.0976311 4.29289 0.292893L5 1ZM8.29289 5.70711C8.68342 6.09763 9.31658 6.09763 9.70711 5.70711C10.0976 5.31658 10.0976 4.68342 9.70711 4.29289L8.29289 5.70711ZM1.70711 5.70711L5.70711 1.70711L4.29289 0.292893L0.292893 4.29289L1.70711 5.70711ZM4.29289 1.70711L8.29289 5.70711L9.70711 4.29289L5.70711 0.292893L4.29289 1.70711Z"
                  :fill="selectionHearAboutUsOpen ? '#14181F' : '#667a84'"
                />
              </svg>
            </div>
            <span
              class="selection__label montserrat"
              :class="
                selectionHearAboutUsOpen || hearAboutUs.length > 0
                  ? 'selection_open'
                  : ''
              "
              >How did you hear about us?</span
            >
            <div
              class="w-full absolute top-[30px] left-0 bg-[transparent] overflow-hidden ease-in duration-300 z-[1] pt-[10px]"
              :class="
                selectionHearAboutUsOpen
                  ? 'max-h-[280px] selection__options'
                  : 'max-h-[0px]'
              "
              :style="
                hearAboutUs.length > 0
                  ? { top: 'calc(100% - 5px)' }
                  : { top: '30px' }
              "
            >
              <p
                v-for="(item, index) in hearAboutUsOptions"
                :key="index"
                v-on:click="
                  hearAboutUs.push(item);
                  previousLength = hearAboutUs.length;
                  hearAboutUsOptions = hearAboutUsOptions.filter(
                    (x) => x != item
                  );
                  hearAboutUsOptions.length == 0
                    ? (selectionHearAboutUsOpen = false)
                    : {};
                "
                class="w-full text-left text-[14px] leading-[17px] font-[500] cursor-pointer hover:bg-[#29ADF2] bg-[white] hover:text-white text-[#112443] flex items-center px-[16px] pt-[12px] pb-[18px]"
              >
                {{ item }}
              </p>
            </div>
          </div>
        </div>
        <!-- HelpInputTextArea -->
        <div>
          <label class="input--big">
            <textarea
              @input="(e) => autogrow(e.target)"
              class="input__field montserrat input__field-big resize-none"
              v-model="v$.info.$model"
              :class="
                v$.info.$errors.length > 0
                  ? 'input__field-error'
                  : v$.info.$model.trim()
                  ? 'input__field-active'
                  : ''
              "
            />
            <span
              class="input__label montserrat"
              :class="v$.info.$model.trim() ? 'not_blank' : ''"
              :style="v$.info.$errors.length > 0 ? { color: '#dc362e' } : {}"
              >How can we help *</span
            >
          </label>
          <div
            class="input-errors"
            v-for="error of v$.info.$errors"
            :key="error.$uid"
          >
            <div class="error-msg">{{ error.$message }}</div>
          </div>
        </div>
        <div
          class="flex flex-col md:flex-row font-[500] text-[12px] leading-[15px] text-[#667A84] tracking-[0.001em] md:items-center items-start justify-between mt-[40px] md:mt-[40px]"
        >
          <div
            v-on:click="check = !check"
            class="cursor-pointer flex items-center"
          >
            <img
              :src="
                check
                  ? require('@/assets/images/checkbox-check.png')
                  : require('@/assets/images/checkbox-uncheck.png')
              "
              alt="checkbox"
            />
            <span class="ml-[16px]">
              I agree and read the
              <span class="underline decoration-solid text-[#29ADF2]"
                >Terms and conditions</span
              >
            </span>
          </div>
          <button
            class="w-full md:w-auto min-w-[140px] h-[40px] text-[16px] leading-[20px] font-[500] rounded-[24px] bg-[#29ADF2] text-white mt-[40px] md:mt-0"
            :disabled="!validate()"
            :class="validate() ? 'opacity-[1] send-button' : 'opacity-[.48]'"
            v-on:click="sendMail()"
          >
            Send
          </button>
        </div>
      </div>
      <div class="z-[-2] relative">
        <LineAnimation
          class="xs:pt-[50px] md:pt-[64px] pr-[50px]"
          :main_title="true"
          :text_color="'#000000'"
          :line_color="'#29adf2'"
          big
        >
          where to <span class="font-bold text-[#29adf2]">find us:</span>
        </LineAnimation>

        <div
          class="grid grid-cols-1 md:grid-cols-2 md:mt-[40px] mb-4 md:mb-[120px] mt-[40px] text-left gap-y-4"
        >
          <h3
            class="font-[700] text-[24px] text-[#29ADF2] leading-[34px] mb-1 md:col-span-2"
          >
            {{ contactData.title }}
          </h3>

          <div class="space-y-4">
            <div>
              <p
                v-for="el in contactData.address"
                :key="el"
                class="font-[300] text-[20px] md:text-[24px] md:leading-[32px] text-[#002132] leading-[29px]"
              >
                {{ el }}
              </p>
            </div>

            <div>
              <p
                v-for="(el, index) in contactData.contact"
                :key="el"
                class="font-[300] text-[20px] md:text-[24px] md:leading-[32px] text-[#002132] leading-[29px]"
              >
                <a v-if="index === 1" :href="`mailto: ${el}`">{{ el }}</a>
                <a v-if="index === 0" :href="`tel:  ${el}`">{{ el }}</a>
              </p>
            </div>
          </div>

          <div class="space-y-4">
            <div>
              <p
                v-for="el in contactData.info"
                :key="el"
                class="font-[300] text-[20px] md:text-[24px] md:leading-[32px] text-[#002132] leading-[29px]"
              >
                {{ el }}
              </p>
            </div>
            <div>
              <p
                v-for="el in contactData.final"
                :key="el"
                class="font-[300] text-[16px] md:text-[18px] md:leading-[26px] text-[#002132] leading-[20px]"
              >
                {{ el }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </PageLayout>
  <div
    v-if="isModalOpen"
    class="w-[100vw] h-[100vh] backdrop-blur fixed top-0 z-[1000] flex justify-center items-center"
  >
    <div class="h-[525px] w-[520px] bg-white p-[24px] modal-wrapper">
      <div class="flex justify-end">
        <img
          alt="close icon"
          src="@/assets/close_icon.svg"
          class="cursor-pointer hover:opacity-50"
          @click="isModalOpen = false"
        />
      </div>
      <p v-if="!isSent" class="font-[500] mt-[10px] h-[90%]">Sending...</p>
      <div v-else class="flex flex-col items-center justify-evenly h-[90%]">
        <div class="flex justify-center">
          <img src="@/assets/lemons.svg" alt="Blue Lemons logo" />
        </div>
        <div>
          <h2 class="modal-text">THANK YOU</h2>
          <p class="font-[500] mt-[10px]">
            Your message has been sent successfully
          </p>
        </div>
        <button class="button-invert w-[180px]" @click="isModalOpen = false">
          Done
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { useHead } from "@vueuse/head";
import { defineComponent } from "vue";
import getSiteMeta from "../utils/getSiteMeta";
import PageLayout from "@/components/PageLayout.vue";
import LineAnimation from "@/components/shared/LineAnimation.vue";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";

export default defineComponent({
  name: "ContactView",
  setup() {
    const meta = {
      title: "Contact | Blue Lemons",
      description: "Where to find us",
      url: "http://bluelemons.sk/contact",
    };

    useHead({
      title: meta.title,
      meta: getSiteMeta(meta),
    });
    return { v$: useVuelidate() };
  },
  data: () => {
    return {
      isModalOpen: false,
      isSent: false,
      name: "",
      email: "",
      company: "",
      number: "",
      info: "",
      employees: "",
      budget: -1,
      selectionServiceOpen: false,
      selectionBudgetOpen: false,
      selectionHearAboutUsOpen: false,
      budgetOptions: [
        "€30k - €90k ",
        "€90k - €250k ",
        "€250k - €500k ",
        "€500k+",
      ],
      service: [],
      serviceOptions: [
        "Web application",
        "Mobile application",
        "Development",
        "Others",
      ],
      previousLength: 0,
      hearAboutUs: [],
      hearAboutUsOptions: [
        "Social media",
        "Search engine",
        "Advertising",
        "Recomended by friend",
        "Other",
      ],
      contactData: {
        title: "Office Košice",
        address: [
          "Blue Lemons  s.r.o.",
          "Bastion, Továrenská 8, ",
          "040 01 Košice",
        ],

        contact: ["+421 901 903 234", "info@bluelemons.sk"],
        info: ["DIČ: 2121302601", "IČO: 53180054"],
        final: [
          "Zapísaný v Obchodnom registri Okresného súdu Košice I, oddiel: Sro, vložka č. 49544/V",
          "Fio banka a.s.",
          "IBAN: SK16 8330 0000 0027 0184 9057",
        ],
      },
      isMounted: false,
      color: "14181F",
      check: false,
    };
  },
  validations() {
    return {
      name: { required },
      email: { required, email },
      number: { required },
      info: { required },
    };
  },
  methods: {
    valid() {
      return false;
    },
    autogrow(element) {
      element.style.height = "5px";
      element.style.height = element.scrollHeight + "px";
    },
    clear() {
      this.name = "";
      this.email = "";
      this.info = "";
      this.number = "";
      this.employees = "";
      this.company = "";
      this.service = [];
      this.hearAboutUs = [];
      this.budget = -1;
    },
    validate() {
      return (
        this.v$.name.$errors.length == 0 &&
        this.v$.name.$model.trim() &&
        this.v$.email.$errors.length == 0 &&
        this.v$.email.$model.trim() &&
        this.v$.info.$errors.length == 0 &&
        this.v$.info.$model.trim() &&
        this.v$.number.$errors.length == 0 &&
        this.v$.number.$model.trim() &&
        this.check
      );
    },
    sendMail() {
      this.isModalOpen = true;
      this.isSent = false;
      fetch(process.env.VUE_APP_EMAIL_API_URL + "/send_bl_mail", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          to: "info@bluelemons.sk",
          name: this.v$.name.$model,
          company: this.company,
          email: this.v$.email.$model,
          phone_number: this.v$.number.$model,
          employees: this.employees,
          services: this.service.toString(),
          budget: this.budgetOptions[this.budget],
          about_us: this.hearAboutUs.toString(),
          help: this.v$.info.$model,
        }),
      }).then(() => {
        this.isSent = true;
        this.clear();
        this.v$.$reset();
        window.lintrk("track", { conversion_id: 9521786 });
      });
    },
  },
  components: {
    PageLayout,
    LineAnimation,
  },
  mounted() {
    this.isMounted = true;
  },
  computed: {
    getHeight() {
      return window.innerHeight;
    },
  },
});
</script>

<style scoped>
.modal-wrapper {
  background: #ffffff;
  box-shadow: 0px 6px 9px rgba(61, 65, 85, 0.06);
  border-radius: 16px;
}
.modal-text {
  font-size: 60px;
  line-height: 60px;
  font-weight: 900;
  color: transparent;
  -webkit-text-stroke: 1px #29adf2;
}
.line-inline p {
  display: flex;
}

@media screen and (min-width: 768px) {
  .bordering:nth-child(odd) {
    border-right: 1px solid #29adf2;
  }
  .bordering:nth-child(even) {
    border-left: 1px solid #29adf2;
  }
}

@media screen and (max-width: 768px) {
  .bordering:nth-child(odd) {
    border-left: 1px solid transparent;
    border-bottom: 1px solid #29adf2;
  }
  .bordering:nth-child(even) {
    border-right: 1px solid transparent;
    border-top: 1px solid;
  }
}
input,
label {
  width: 240px;
  display: block;
}

label {
  text-align: left;
}
.input {
  position: relative;
  width: 100%;
}
.input--big {
  position: relative;
  width: 100%;
  margin-top: 32px;
}
.input__label {
  position: absolute;
  left: 0;
  top: 0;
  padding: calc(0.5rem * 0.55) calc(0.5rem * 0.5);
  margin: calc(0.5rem * 0.55 + 3px) calc(0.5rem * 0.5);
  padding-left: 16px;
  white-space: nowrap;
  transform: translate(0, 0);
  transform-origin: 0 0;
  background: transparent;
  transition: transform 120ms ease-in;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  color: #667a84;
}
.selection__label {
  position: absolute;
  left: 0;
  top: 0;
  padding: calc(0.5rem * 0.55) calc(0.5rem * 0.5);
  margin: calc(0.5rem * 0.55 + 3px) calc(0.5rem * 0.5);
  padding-left: 16px;
  white-space: nowrap;
  transform: translate(0, 0);
  transform-origin: 0 0;
  background: transparent;
  transition: transform 120ms ease-in;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  color: #667a84;
}
.input__field {
  box-sizing: border-box;
  display: block;
  width: 100%;
  border: 1px solid #667a84;
  border-radius: 12px;
  padding: 16px;
  height: 40px;
  color: #002132;
  background: transparent;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
}
.budget__field {
  text-align: left;
  padding: 11px 16px;
  margin: auto 0;
  color: #002132;
  background: transparent;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
}
.not_blank {
  transition: transform 120ms ease-in;
  transform: translate(0.25rem, -19px) scale(0.95);
  color: #002132;
}
.input__field:focus + .input__label {
  transition: transform 120ms ease-in;
  transform: translate(0.25rem, -19px) scale(0.95);
  color: #29adf2;
}
.input__field:focus + .input__label::before {
  content: "";
  z-index: -1;
  position: absolute;
  top: 13px;
  width: 100%;
  height: 1px;
  left: 5px;
  background-color: #eaf7fe;
}

.not_blank::before {
  z-index: -1;
  content: "";
  position: absolute;
  top: 13px;
  width: 100%;
  height: 1px;
  left: 5px;
  background-color: #eaf7fe;
}
.input__field:focus {
  border: 1px #29adf2 solid;
  background-color: #eaf7fe;
}
.input__field-error {
  border: 1px #dc362e solid;
  color: #dc362e;
  background-color: #fceeee;
}
.input__field-error:focus {
  border: 1px #dc362e solid;
  color: #dc362e;
  background-color: #fceeee;
}
.input__field-active {
  border: 1px #29adf2 solid;
}
textarea:focus,
input:focus,
.selection:focus {
  outline: none;
}
.selection {
  border: 1px solid #667a84;
  border-radius: 12px;
}
.selection:focus {
  border: 1px #29adf2 solid;
  background-color: #eaf7fe;
}
.selection:focus {
  border: 1px #29adf2 solid;
  background-color: #eaf7fe;
}
.selection_active {
  border: 1px #29adf2 solid;
}
.selection_open {
  transition: transform 120ms ease-in;
  transform: translate(0.25rem, -19px) scale(0.95);
  color: #29adf2;
}
.selection_open::before {
  z-index: -1;
  content: "";
  position: absolute;
  top: 12px;
  width: 100%;
  height: 1px;
  left: 5px;
  background-color: #eaf7fe;
}
.selection__options {
  border: 1px solid #29adf2;
  border-top: transparent;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
.input-errors {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
}
.error-msg {
  width: 240px;
  text-align: left;
  font-size: 10px;
  padding: 2px 1px;
  color: #dc362e;
}
.input__field-big {
  width: 100% !important;
  min-height: 80px;
  scrollbar-color: #29adf2;
  scrollbar-width: thin;
  overflow: hidden;
}
::-webkit-scrollbar {
  width: 3px;
}
/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #141414;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.send-button:active {
  background-color: #218ac2;
}
</style>
