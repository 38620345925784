<template>
  <div class="md:mx-0 flex justify-center cursor-pointer">
    <div
      class="text-button min-w-[154px] flex md:block relative md:mx-0 md:w-auto"
    >
      <video
        width="154"
        height="92"
        autoplay
        loop
        muted
        class="mx-auto z-[-1] md:hidden"
        v-if="eyeLeft"
        playsinline
      >
        <source src="@/assets/animations/eye.mp4" type="video/mp4" />
      </video>
      <button class="z-20 eye-button">
        View<br />{{ less ? "less" : "more" }}
      </button>
      <video
        width="154"
        height="92"
        autoplay
        loop
        muted
        class="mx-auto z-[-1] md:mt-[-11px] relative"
        :class="eyeLeft ? 'hidden md:block' : ''"
        playsinline
      >
        <source src="@/assets/animations/eye.mp4" type="video/mp4" />
      </video>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "EyeButton",
  props: {
    less: {
      type: Boolean,
    },
    eyeLeft: {
      type: Boolean,
    },
  },
});
</script>

<style scoped>
.eye-button {
  font-size: 40px;
  line-height: 40px;
}
</style>
