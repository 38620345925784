<template>
  <PageLayout show_drop :showContact="true">
    <div
      class="flex items-start h-[calc(100vh-75px)] md:h-[calc(100vh-100px)] w-full max-width-limit relative lg:mx-auto top-container mb-[50px]"
    >
      <div class="w-full mx-auto top-[-20px] h-full relative object-fit">
        <video
          v-if="isFirstAnimation"
          class="absolute left-[-5.5%] md:h-auto w-[110%] max-w-[110%] hidden md:block big-video-clip"
          ref="video"
          preload
          muted
          src="@/assets/animations/intro.mp4"
          playsinline
        />
        <img
          alt="home title"
          v-else
          class="absolute left-[-5.5%] md:h-auto w-[110%] max-w-[110%] hidden md:block big-video-clip"
          src="@/assets/animations/intro.jpg"
        />
        <video
          v-if="isFirstAnimation"
          class="absolute md:hidden top-[20px] h-auto left-[-3.5%] w-[106%] max-w-[108%] mr-[-25px] overflow-hidden video-clip"
          ref="mobile-video"
          preload
          muted
          playsinline
          autoplay
        >
          <source src="@/assets/animations/intro_mobile.mp4" type="video/mp4" />
        </video>
        <img
          alt="home title mobile"
          v-else
          class="absolute md:hidden top-[20px] h-auto left-[-3.5%] w-[106%] max-w-[110%] mr-[-25px] overflow-hidden video-clip"
          src="@/assets/animations/intro_mobile.jpg"
        />
      </div>
      <div class="absolute right-0 bottom-0 scroll-container">
        <span
          class="block rotate-90 h-[143px] mr-[16px] md:mr-[64px] xl:mr-[130px] mb-[180px]"
        >
          <span
            class="inline-block bounce h-[1px] w-[76px] bg-[#29ADF2] mb-[0.3em] mr-[16px]"
          />
          <span
            class="animate-pulse text-[18px] text-[#29ADF2] font-light montserrat"
            >Scroll</span
          >
        </span>
      </div>
    </div>
    <div class="relative max-w-[1440px] mx-auto">
      <div
        v-on:click="
          () => {
            muted = !muted;
          }
        "
        @mouseover="upHere = true"
        @mouseleave="upHere = false"
        class="absolute top-[5px] right-[5px] md:top-[32px] md:right-[40px] h-[56px] w-[56px] z-[10] cursor-pointer scale-50 md:scale-100"
        :class="muted ? 'off' : 'on'"
      ></div>
      <video
        class="w-[100vw] mb-[45px] max-w-[1440px] mx-auto object-cover video-clip relative"
        ref="blvideo"
        preload
        :muted="muted"
        loop
        playsinline
        autoplay
      >
        <source src="@/assets/animations/blvideo.mp4" type="video/mp4" />
      </video>
    </div>
    <div
      class="flex justify-center items-center relative pb-[100px] md:py-[100px]"
    >
      <Slider :slides="slides" />
    </div>

    <div
      class="max-width-limit grid grid-cols-2 md:flex md:flex-nowrap justify-between items-end relative opacity-0 max-h-[100vh] transition ease-in duration-[1000ms] numbers"
      :class="{ 'opacity-100': isStatsInView }"
      ref="stats"
    >
      <Number
        :value="70"
        :start="isStatsInView"
        :delay="1000"
        subtitle="professionals"
        plus
      />
      <Number
        :value="10"
        :start="isStatsInView"
        :delay="500"
        subtitle="roles"
        class="basis-1/4"
      />
      <Number
        :value="45"
        :start="isStatsInView"
        :delay="1000"
        subtitle="projects"
        class="basis-1/4"
        plus
      />
      <Number
        :value="15"
        :start="isStatsInView"
        :delay="1000"
        subtitle="technologies"
      />
    </div>

    <img
      :src="balkon"
      alt="photo from blue lemons company"
      class="z-[-1] w-full object-cover"
    />
    <Partners :basic="true" />
  </PageLayout>
</template>

<script>
import Number from "@/components/Number.vue";
import PageLayout from "@/components/PageLayout.vue";
import Partners from "@/components/shared/Partners.vue";
import Slider from "@/components/shared/Slider.vue";

import balkon from "@/assets/images/balkon.png";

import { useHead } from "@vueuse/head";
import { defineComponent } from "vue";
import getSiteMeta from "../utils/getSiteMeta";

export default defineComponent({
  components: {
    Number,
    PageLayout,
    Partners,
    Slider,
  },
  data: () => {
    return {
      balkon: balkon,
      isStatsInView: false,
      muted: true,
      upHere: false,
      slides: [
        {
          content: "Slide content.",
          path: require("@/assets/carousel/Ucko.png"),
          pathMobile: require("@/assets/carousel/home-mobile/ucko.png"),
          app: "Učko",
          subtitle: "Custom development",
          link: "ucko",
        },
        {
          content: "Slide content.",
          path: require("@/assets/carousel/EFFY.png"),
          pathMobile: require("@/assets/carousel/home-mobile/effy.png"),
          app: "Effy",
          subtitle: "Mobile app",
          link: "effy",
        },
        {
          content: "Slide content.",
          path: require("@/assets/carousel/ESAC.png"),
          pathMobile: require("@/assets/carousel/home-mobile/esac.png"),
          app: "Esac",
          subtitle: "Mobile app",
          link: "esac",
        },
        {
          content: "Slide content.",
          path: require("@/assets/carousel/Tessa.png"),
          pathMobile: require("@/assets/carousel/home-mobile/tessa.png"),
          app: "Tessa",
          subtitle: "Web app",
          link: "tessa",
        },
        {
          content: "Slide content.",
          path: require("@/assets/carousel/Way2Eco.png"),
          pathMobile: require("@/assets/carousel/home-mobile/Way2Go.png"),
          app: "Way2Eco",
          subtitle: "Web app",
          link: "way2eco",
        },
        {
          content: "Slide content.",
          path: require("@/assets/carousel/6.png"),
          pathMobile: require("@/assets/carousel/home-mobile/6m.png"),
          last: "true",
          link: "",
        },
      ],
    };
  },
  methods: {
    checkScroll() {
      if (
        window.scrollY + window.innerHeight >
        this.$refs.stats?.offsetTop + 300
      )
        this.isStatsInView = true;
    },
    play() {
      this.$refs.blvideo?.play();
    },
    stop() {
      this.$refs.blvideo?.pause();
    },
    checkScrollBlVideo() {
      if (
        window.scrollY + window.innerHeight >
        this.$refs.blvideo?.offsetTop + 600
      ) {
        this.play();
      }
    },
  },
  computed: {
    getHeight() {
      return window.innerHeight;
    },
    isFirstAnimation() {
      return !window.first_animation;
    },
  },
  setup() {
    const meta = {
      title: "Squeeze out innovations | Blue Lemons",
      description:
        "We create digital products that drive profit, ability and growth. We create products that people love to use.",
      url: "http://bluelemons.sk/",
    };

    useHead({
      title: meta.title,
      meta: getSiteMeta(meta),
    });
  },
  mounted() {
    if (!window.first_animation) {
      setTimeout(() => {
        this.$refs.video?.play();
        this.$refs["mobile-video"]?.play();
        window.first_animation = true;
      }, 1500);
    }
    window.scrollTo({ top: 0 });
    window.addEventListener("scroll", this.checkScroll);
    window.addEventListener("scroll", this.checkScrollBlVideo);
  },
  unmounted() {
    window.removeEventListener("scroll", this.checkScroll);
    window.removeEventListener("scroll", this.checkScrollBlVideo);
  },
});
</script>

<style scoped>
.off {
  background-image: url("@/assets/sound-icons/off-basic.svg");
}
.on {
  background-image: url("@/assets/sound-icons/on-basic.svg");
}
.off:hover {
  background-image: url("@/assets/sound-icons/off-hover.svg");
}
.on:hover {
  background-image: url("@/assets/sound-icons/on-hover.svg");
}
.off:active {
  background-image: url("@/assets/sound-icons/off-clicked.svg");
}
.on:active {
  background-image: url("@/assets/sound-icons/on-clicked.svg");
}
/* This used to work for the parent element of button divs */
/* But it does not work with newer browsers, the below doesn't hide the play button parent div */

*::-webkit-media-controls-panel {
  display: none !important;
  -webkit-appearance: none;
}

/* Old shadow dom for play button */

*::-webkit-media-controls-play-button {
  display: none !important;
  -webkit-appearance: none;
}

/* New shadow dom for play button */

/* This one works! */

*::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}

:host::shadow::-webkit-media-controls-container {
  display: none !important;
  -webkit-appearance: none;
}

.top-container {
  @apply pt-[30px];
}

.numbers {
  margin-bottom: calc(100px - 15%);
}

.big-video-clip {
  overflow: hidden;
  clip-path: inset(0 2px 0 2px);
}

.video-clip {
  clip-path: inset(2px 0 2px 0);
}

/*@media screen and (max-height: 90px) {*/
/*  .big-video-clip {*/
/*    @apply h-full;*/
/*  }*/
/*}*/

@media screen and (max-height: 720px) and (min-width: 450px) {
  .video-clip {
    @apply h-full top-[0px];
    object-fit: scale-down;
  }
}

@media screen and (max-height: 950px) {
  .scroll-container {
    @apply bottom-0 mb-[40px];
  }

  .scroll-container > span {
    @apply mb-[30px];
  }

  .top-container {
    @apply items-center;
  }

  .big-video-clip {
    @apply mb-[30px] h-full;
  }
}

@media screen and (min-height: 950px) and (min-width: 768px) {
  .top-container {
    @apply top-[90px];
  }
}

@media only screen and (max-height: 1100px) {
  .top-container {
    @apply items-center;
  }

  .big-video-clip {
    object-fit: scale-down;
  }
}
</style>
