<template>
  <div
    class="flex flex-col items-center w-full"
    :class="basic ? ' bg-[#25AEF2]' : ' bg-transparent'"
  >
    <LineAnimation
      v-if="basic"
      class="w-full lg:pl-[130px] lg:my-[80px] xs:my-[40px] xs:pl-[16px] md:max-w-[1440px]"
      :text="'Our partners'"
      :line_color="'white'"
      :text_color="'white'"
    />
    <div class="max-w-[1440px]">
      <div
        class="grid xs:grid-cols-2 lg:grid-cols-4 md:gap-[20px] md:gap-[80px] lg:px-[130px] xs:px-[30px]"
        :class="basic ? 'mb-[30px]lg:pb-[80px]' : 'mb-[10px] lg:pb-[0px]'"
      >
        <div
          v-for="icon in basic ? partners_color.slice(0, 8) : partners_color"
          :key="icon.title"
          :href="icon.link"
          @mouseover="icon.hover = true"
          @mouseleave="icon.hover = false"
        >
          <div class="flex h-full w-full flex-col items-center justify-center">
            <div class="max-w-[200px] max-h-[180px] p-[20px]">
              <img
                class="h-auto resize"
                :title="icon.title"
                :alt="icon.alt"
                :src="
                  basic
                    ? icon.path_white
                    : icon.hover
                    ? icon.path_color
                    : icon.path_grey
                "
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";
import LineAnimation from "@/components/shared/LineAnimation.vue";

@Options({
  props: {
    basic: Boolean,
  },
  components: {
    LineAnimation,
  },
})
export default class Partners extends Vue {
  msg!: string;
  partners = [];
  indexes = [0, 1, 2, 4, 5, 6, 7, 9];
  partners_color = [
    {
      path_color: require("@/assets/partners/color_style/pelikan.png"),
      path_grey: require("@/assets/partners/grey_style/pelikan.png"),
      path_white: require("@/assets/partners/white_style/pelikan.png"),
      alt: "Pelikan partner",
      title: "Pelikan partner",
      hover: false,
    },
    {
      path_color: require("@/assets/partners/color_style/doprava.png"),
      path_grey: require("@/assets/partners/grey_style/doprava.png"),
      path_white: require("@/assets/partners/white_style/doprava.png"),
      alt: "Ministerstvo dopravy partner",
      title: "Ministerstvo dopravy partner",
      hover: false,
    },
    {
      path_color: require("@/assets/partners/color_style/uss.png"),
      path_grey: require("@/assets/partners/grey_style/uss.png"),
      path_white: require("@/assets/partners/white_style/uss.png"),
      alt: "U. S. Steel partner",
      title: "U. S. Steel partner",
      hover: false,
    },
    {
      path_color: require("@/assets/partners/color_style/charter.png"),
      path_grey: require("@/assets/partners/grey_style/charter.png"),
      path_white: require("@/assets/partners/white_style/charter.png"),
      alt: "Charter partner",
      title: "Charter partner",
      hover: false,
    },
    {
      path_color: require("@/assets/partners/color_style/bdo.png"),
      path_grey: require("@/assets/partners/grey_style/bdo.png"),
      path_white: require("@/assets/partners/white_style/bdo.png"),
      alt: "BDO",
      title: "BDO",
      hover: false,
    },
    {
      path_color: require("@/assets/partners/color_style/nts.png"),
      path_grey: require("@/assets/partners/grey_style/nts.png"),
      path_white: require("@/assets/partners/white_style/nts.png"),
      alt: "NTS",
      title: "NTS",
      hover: false,
    },
    {
      path_color: require("@/assets/partners/color_style/skolstvo.png"),
      path_grey: require("@/assets/partners/grey_style/skolstvo.png"),
      path_white: require("@/assets/partners/white_style/skolstvo.png"),
      alt: "Ministerstvo vedy a výskumu",
      title: "Ministerstvo vedy a výskumu",
      hover: false,
    },
    {
      path_color: require("@/assets/partners/color_style/asseco.png"),
      path_grey: require("@/assets/partners/grey_style/asseco.png"),
      path_white: require("@/assets/partners/white_style/asseco.png"),
      alt: "asseco",
      title: "asseco",
      hover: false,
    },
    {
      path_color: require("@/assets/partners/color_style/ombudsman.png"),
      path_grey: require("@/assets/partners/grey_style/ombudsman.png"),
      alt: "Zvierací ombudsman",
      title: "Zvierací ombudsman",
      hover: false,
    },
    {
      path_color: require("@/assets/partners/color_style/bielanoc.png"),
      path_grey: require("@/assets/partners/grey_style/bielanoc.png"),
      alt: "Biela noc",
      title: "Biela noc",
      hover: false,
    },
    {
      path_color: require("@/assets/partners/color_style/nds.png"),
      path_grey: require("@/assets/partners/grey_style/nds.png"),
      alt: "Národná dialničná spoločnosť",
      title: "Národná dialničná spoločnosť",
      hover: false,
    },
    {
      path_color: require("@/assets/partners/color_style/swift.png"),
      path_grey: require("@/assets/partners/grey_style/swift.png"),
      alt: "Swift - Shift",
      title: "Swift - Shift",
      hover: false,
    },
    {
      path_color: require("@/assets/partners/color_style/esac.png"),
      path_grey: require("@/assets/partners/grey_style/esac.png"),
      alt: "Esac",
      title: "Esac",
      hover: false,
    },
    {
      path_color: require("@/assets/partners/color_style/divadlo.png"),
      path_grey: require("@/assets/partners/grey_style/divadlo.png"),
      alt: "Divadlo na peróne",
      title: "Divadlo na peróne",
      hover: false,
    },
    {
      path_color: require("@/assets/partners/color_style/visit.png"),
      path_grey: require("@/assets/partners/grey_style/visit.png"),
      alt: "Visit Košice",
      title: "Visit Košice",
      hover: false,
    },
    {
      path_color: require("@/assets/partners/color_style/wexo.png"),
      path_grey: require("@/assets/partners/grey_style/wexo.png"),
      alt: "WexoPay",
      title: "WexoPay",
      hover: false,
    },
    {
      path_color: require("@/assets/partners/color_style/airbank.png"),
      path_grey: require("@/assets/partners/grey_style/airbank.png"),
      alt: "AirBank",
      title: "AirBank",
      hover: false,
    },
    {
      path_color: require("@/assets/partners/color_style/wienerberger.png"),
      path_grey: require("@/assets/partners/grey_style/wienerberger.png"),
      alt: "wienerberger",
      title: "wienerberger",
      hover: false,
    },
    {
      path_color: require("@/assets/partners/color_style/hypo.png"),
      path_grey: require("@/assets/partners/grey_style/hypo.png"),
      alt: "hypo",
      title: "hypo",
      hover: false,
    },
    {
      path_color: require("@/assets/partners/color_style/mastercard.png"),
      path_grey: require("@/assets/partners/grey_style/mastercard.png"),
      alt: "mastercard",
      title: "mastercard",
      hover: false,
    },
  ];
}
</script>
<style scoped>
.custom-image {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
</style>
