<template>
  <div class="flex items-start">
    <div class="flex items-start py-[10px] md:py-[30px]">
      <button
        class="special-text-button big-text whitespace-nowrap"
        :class="selected ? 'text-lemonblue' : ''"
      >
        {{ text }}
      </button>
      <div class="relative ml-[8px] md:ml-0 md:mt-[-20px]">
        <svg
          width="50"
          height="40"
          viewBox="0 0 50 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="hidden md:block mt-[10px]"
        >
          <path
            d="M49.2163 19.275C49.2163 9.18826 39.9292 0.646051 25.114 1.0113C11.8027 1.0113 1.01172 9.18826 1.01172 19.275"
            stroke="#29ADF2"
          />
          <path
            d="M1.01128 19.2752C0.569033 31.3292 12.6867 39 25.998 39"
            stroke="#29ADF2"
          />
          <path
            d="M49.2054 18.5448C49.6515 30.7255 30.4204 44.114 11.625 33.1557"
            stroke="#29ADF2"
          />
        </svg>
        <svg
          width="28"
          height="22"
          viewBox="0 0 50 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="md:hidden"
        >
          <path
            d="M49.2163 19.275C49.2163 9.18826 39.9292 0.646051 25.114 1.0113C11.8027 1.0113 1.01172 9.18826 1.01172 19.275"
            stroke="#29ADF2"
          />
          <path
            d="M1.01128 19.2752C0.569033 31.3292 12.6867 39 25.998 39"
            stroke="#29ADF2"
          />
          <path
            d="M49.2054 18.5448C49.6515 30.7255 30.4204 44.114 11.625 33.1557"
            stroke="#29ADF2"
          />
        </svg>
        <div
          class="absolute top-[3px] md:top-[-20px] w-[28px] md:w-[50px] flex justify-center"
        >
          <p class="value-text">{{ value }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ButtonWithCircle",
  props: {
    text: {
      type: String,
      required: true,
    },
    value: Number,
    selected: Boolean,
  },
};
</script>

<style scoped>
.big-text {
  font-size: 90px !important;
}
.value-text {
  font-weight: 300;
  font-size: 24px;
  line-height: 101px;
  -webkit-text-stroke: 0 #000;
  @apply text-lemonblue;
}

@media screen and (max-width: 768px) {
  .value-text {
    font-size: 12px;
    line-height: 15px;
  }

  .big-text {
    font-size: 34px !important;
    line-height: 41px;
  }
}
</style>
