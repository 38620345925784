import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-50ad60a4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-left" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      ref: "line",
      class: _normalizeClass(["montserrat xl:text-[34px] md:text-[27px] xs:text-[18px] transition ease-in duration-[1500ms] opacity-0 line-text", _ctx.showAnimation ? 'line-animation opacity-100' : 'line'])
    }, [
      _createElementVNode("p", {
        class: _normalizeClass(["xs:w-[100%] line-text", {
          'md:text-[48px] text-[30px]': _ctx.big,
        }]),
        style: _normalizeStyle('color: ' + _ctx.text_color)
      }, [
        _createElementVNode("span", {
          class: _normalizeClass(_ctx.showAnimation ? 'line-animation2' : 'line2'),
          style: _normalizeStyle('background: ' + _ctx.line_color)
        }, null, 6),
        _createTextVNode(_toDisplayString(_ctx.text) + " ", 1),
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ], 6)
    ], 2)
  ]))
}