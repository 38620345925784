<template>
  <PageLayout>
    <PageTitle :first_line="'ORGANIC'" :second_line="'& HEALTHY'"
      ><span class="font-bold text-[#29adf2]">cooperation</span> with our
      clients<br class="hidden lg:block" />
      that
      <span class="font-bold text-[#29adf2]"
        >we are <span class="whitespace-nowrap">proud of.</span></span
      ></PageTitle
    >
    <Partners :basic="false" />
  </PageLayout>
</template>

<script lang="ts">
import { useHead } from "@vueuse/head";
import { defineComponent } from "vue";
import getSiteMeta from "../utils/getSiteMeta";
import PageLayout from "@/components/PageLayout.vue";
import Partners from "@/components/shared/Partners.vue";
import PageTitle from "@/components/shared/PageTitle.vue";

export default defineComponent({
  name: "ClientsView",
  components: {
    PageLayout,
    Partners,
    PageTitle,
  },
  setup() {
    const meta = {
      title: "Clients | Blue Lemons",
      description:
        "Organic and healthy cooperation with our clients that we are proud of.",
      url: "http://bluelemons.sk/clients",
    };

    useHead({
      title: meta.title,
      meta: getSiteMeta(meta),
    });
  },
});
</script>
